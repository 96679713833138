.imm__applicants_dropdown__container__open {
    background-color: var(--white_color);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .imm__ad__component {
        .imm__ad__select {
            svg {
                rotate: 180deg;
            }
        }
    }

    .imm__ad__content {
        background-color: var(--white_color);
    }
}

.imm__applicants_dropdown__container {
    max-width: fit-content;
    margin: 0 auto;
    padding: 10px;
    transition: all 0.3s ease;
    position: relative;

    .imm__ad__component {
        cursor: pointer;
        text-align: center;

        .imm__ad__select {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            min-width: 200px;
            position: relative;
            padding: 7px 0;
            user-select: none;
            background-color: var(--white_color);
            border-radius: 30px;
            border: 2px solid var(--medium_color);

            &.imm__ad__spouse {
                min-width: 150px;

                svg {
                    position: relative;
                    right: inherit;
                }
            }

            span {
                font-weight: 600;
            }

            svg {
                position: absolute;
                right: 10px;
                transition: all 0.3s ease;
                stroke-width: 0.5px;
            }
        }

        .imm__ad__content {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: map-get($z_indexes, dropdown);
            width: 100%;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            padding: 0 10px 10px 10px;

            .imm__ad__content_option {
                padding: 7px 0;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #eeeeee;
                border-radius: 30px;
            }
        }
    }
}