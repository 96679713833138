.imm__book_consult__page_view {
    background: var(--white_color);
    overflow-x: hidden;

    .imm__book_consult__banner_section {
        width: 100%;
        background-color: #ecf4ff;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 40px;
        padding: 60px 0 60px 140px;

        .banner_title {
            font-size: 70px;
            font-weight: 600;
            color: var(--primary_color);
            z-index: 1;
            position: inherit;
            max-width: 400px;
            text-align: start;
            line-height: 70px;
        }

        button {
            z-index: 1;
            position: inherit;
            background-color: #39b54a;
            color: white;
            border: none;
            outline: none;
            transition: 0.3s all;
            font-size: 22px;
            font-weight: 600;
            padding: 15px 40px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: #1e972e;
                border: none;
                outline: none;
            }
        }

        img {
            position: absolute;
            right: 10px;
            bottom: 0;
            z-index: 0;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .imm__book_consult__why_book_section {
        padding: 60px 0;
        max-width: 1140px;
        margin: 0 auto;

        .why_book_title {
            font-size: 50px;
            font-weight: 600;
            color: var(--primary_color);
            margin-bottom: 40px;
        }

        .why_book_item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 40px;
            margin-bottom: 40px;

            .sr_number {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                height: 100px;
                min-height: 100px;
                width: 100px;
                min-width: 100px;
                color: white;
                background-color: var(--primary_color);
                font-size: 50px;
                font-weight: 600;
            }

            .item_para {
                color: var(--primary_color);
                font-size: 25px;
                font-weight: 500;
                width: 100%;
                text-align: justify;
                line-height: 35px;

                span {
                    font-weight: 600;
                    color: #39b54a;
                }
            }
        }
    }

    .imm__book_consult__meet_section {
        width: 100%;
        background-color: #ecf4ff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;

        .titles {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 40px;
            padding-left: 100px;
        }

        .meet_title {
            font-size: 40px;
            font-weight: 600;
            color: var(--primary_color);
            text-align: start;
            line-height: 50px;
        }

        .meet_para {
            font-size: 20px;
            font-weight: 500;
            color: var(--primary_color);
            text-align: start;
            line-height: 30px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .imm__book_consult__recog_section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 50px 0;
        width: 100%;

        button {
            z-index: 1;
            position: inherit;
            background-color: #39b54a;
            color: white;
            border: none;
            outline: none;
            transition: 0.3s all;
            font-size: 22px;
            font-weight: 600;
            padding: 15px 40px;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 30px;

            &:hover {
                background-color: #1e972e;
                border: none;
                outline: none;
            }
        }

        .recog_para {
            color: #8a8a8e;
            font-size: 20px;
            max-width: 650px;
            margin-bottom: 60px !important;
        }

        .title {
            color: var(--primary_color);
            font-size: 25px;
            font-weight: 600;
            max-width: 650px;
            margin-bottom: 50px;
        }

        .recog_items {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            width: 100%;

            .recog_item_ele {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                background-color: #ecf4ff;
                width: 300px;
                height: 320px;
                padding: 0 20px;
                border-radius: 10px;

                img {
                    height: 50px;
                    width: 50px;
                    object-fit: contain;
                }

                .item_title {
                    color: var(--primary_color);
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    color: #8a8a8e;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .imm__book_consult__banner_section {
        padding-left: 40px !important;

        .banner_title {
            font-size: 50px !important;
            line-height: 60px !important;
        }

        button {
            font-size: 16px !important;
        }
    }

    .imm__book_consult__why_book_section {
        padding-left: 20px !important;
        padding-right: 20px !important;

        .why_book_title {
            font-size: 30px !important;
        }

        .why_book_item {
            .sr_number {
                height: 70px !important;
                min-height: 70px !important;
                width: 70px !important;
                min-width: 70px !important;
                font-size: 30px !important;
            }

            .item_para {
                font-size: 17px !important;
                line-height: 35px !important;
            }
        }
    }

    .imm__book_consult__meet_section {
        .titles {
            padding-left: 70px !important;
        }

        .meet_title {
            font-size: 30px !important;
            line-height: 40px !important;
        }

        .meet_para {
            font-size: 15px !important;
            line-height: 25px !important;
        }

        img {
            width: 400px !important;
        }
    }

    .imm__book_consult__recog_section {
        button {
            font-size: 16px !important;
        }

        .recog_para {
            font-size: 16px !important;
            max-width: 650px !important;
        }

        .title {
            font-size: 23px !important;
        }

        .recog_items {
            .recog_item_ele {
                width: 220px !important;
                height: 300px !important;

                .item_title {
                    font-size: 16px !important;
                }

                p {
                    font-size: 14px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 843px) {
    .imm__book_consult__banner_section {
        padding: 40px 15px !important;
        flex-direction: column !important;
        align-items: center !important;

        .banner_title {
            font-size: 30px !important;
            line-height: 40px !important;
            text-align: center !important;
            max-width: 100% !important;
        }

        button {
            font-size: 12px !important;
            padding: 10px 20px !important;
            width: 100% !important;
            max-width: 400px !important;
        }

        img {
            position: initial !important;
        }
    }

    .imm__book_consult__why_book_section {
        padding: 40px 15px !important;

        .why_book_title {
            font-size: 25px !important;
            margin-bottom: 30px !important;
        }

        .why_book_item {
            gap: 20px !important;
            margin-bottom: 20px !important;

            .sr_number {
                height: 30px !important;
                min-height: 30px !important;
                width: 30px !important;
                min-width: 30px !important;
                font-size: 15px !important;
            }

            .item_para {
                font-size: 12px !important;
                line-height: 17px !important;
            }
        }
    }

    .imm__book_consult__meet_section {
        flex-direction: column !important;
        padding: 40px 15px !important;
        gap: 20px !important;

        .titles {
            padding-left: 0 !important;
            justify-content: center !important;
        }

        .meet_title {
            font-size: 20px !important;
            line-height: 25px !important;
            text-align: center !important;
        }

        .meet_para {
            font-size: 10px !important;
            line-height: 15px !important;
            text-align: center !important;
            align-self: stretch !important;
        }

        img {
            width: 100% !important;
        }
    }

    .imm__book_consult__recog_section {
        padding: 40px 15px !important;

        button {
            font-size: 12px !important;
            padding: 10px 20px !important;
            width: 100% !important;
            margin-bottom: 20px !important;
            max-width: 300px !important;
        }

        .recog_para {
            font-size: 13px !important;
            max-width: 80% !important;
            margin-bottom: 40px !important;
        }

        .title {
            font-size: 18px !important;
            margin-bottom: 30px !important;
        }

        .recog_items {
            flex-direction: column !important;
            gap: 20px !important;

            .recog_item_ele {
                width: 100% !important;
                height: 250px !important;
                max-width: 300px !important;

                .item_title {
                    font-size: 16px !important;
                }

                p {
                    font-size: 14px !important;
                }
            }
        }
    }
}