.imm__ai_chat__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .imm__ai_chat__container__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        font-size: 24px;
        color: var(--primary_color);
    }

    .imm__ai_chat__container__error {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        font-size: 24px;
        color: var(--error_color);
    }
    
    .imm__ai_chat__container__header {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        padding: 20px;
        background-color: #D9E1F2;
        
        .imm__ai_chat__container__header__action_items {
            position: relative;
            width: 100%;
            display: flex;
            gap: 14px;
            align-items: flex-start;

            .imm__ai_chat__container__header__action_items__btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 16px;
                border-radius: 35px;
                border: 1px solid var(--primary_color);
                background-color: var(--white_color);
                font-size: 16px;
                font-weight: 700;

                img {
                    margin-left: -8px;
                }
            }

            .imm__ai_chat__container__header__action_items__btn_selected {
                background-color: var(--primary_color);
                color: var(--white_color);
                cursor: default;
            }

            .imm__ai_chat__container__header__action_items__btn_close {
                position: absolute;
                right: -8px;
                top: -8px;
                svg {
                    width: 24px;
                    height: 24px;
                    align-self: flex-end;
                    stroke-width: 4px;
                }
            }
        }

        .imm__ai_chat__container__header__content {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;

            .imm__ai_chat__container__header__content__logo__container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 84px;
                height: 84px;
                background-color: var(--white_color);
                border-radius: 50%;
                padding: 0 4px;

                .imm__ai_chat__container__header__content__logo {
                    width: 72px;
                    height: 72px;
                }
            }

            .imm__ai_chat__container__header__content__text {
                display: flex;
                flex-direction: column;

                .imm__ai_chat__container__header__content__text__subtitle {
                    font-size: 14px;
                }

            }
        }
    }

    .imm__ai_chat__container__chat_box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 50vh;
        .imm__ai_chat__container__chat_box__chat_list {
            overflow-y: auto;
            max-height: 100%;
            padding: 20px 12px;
            
            .imm__ai_chat__container__chat_box__chat_user {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;
                padding-left: 16%;
                margin: 8px 0;
                &:first-child {
                    margin-top: 0;
                }

                .imm__ai_chat__container__chat_box__chat_user_metadata {
                    display: flex;
                    gap: 6px;
                    width: fit-content;

                    .imm__ai_chat__container__chat_box__chat_user_metadata__avatar {
                        display: none;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background-color: var(--primary_color);
                    }

                    .imm__ai_chat__container__chat_box__chat_user_metadata__name,
                    .imm__ai_chat__container__chat_box__chat_user_metadata__time
                     {
                        font-size: 12px;
                        color: #667085;
                    }
                }

                .imm__ai_chat__container__chat_box__chat_user_text {
                    background-color: #D9E1F2;
                    color: var(--dark_color);
                    border-radius: 10px;
                    padding: 5px 16px;
                    font-size: 16px;
                    line-height: normal;
                    width: fit-content;
                }
                
                .imm__ai_chat__container__chat_box__chat_user_time {
                    font-size: 10px;
                    color: var(--secondary_color);
                    margin-top: 4px;
                }
            }
    
            .imm__ai_chat__container__chat_box__chat_bot {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                padding-right: 16%;

                .imm__ai_chat__container__chat_box__chat_bot_metadata {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    width: fit-content;
                    margin-bottom: 8px;

                    .imm__ai_chat__container__chat_box__chat_bot_metadata__avatar {
                        min-width: 24px;
                        min-height: 24px;
                        max-width: 24px;
                        max-height: 24px;
                        border-radius: 50%;
                    }

                    .imm__ai_chat__container__chat_box__chat_bot_metadata__name,
                    .imm__ai_chat__container__chat_box__chat_bot_metadata__time
                     {
                        font-size: 12px;
                        color: #667085;
                    }
                }
                
                .imm__ai_chat__container__chat_box__chat_bot_text {
                    border: 1px solid #E3E3E3;
                    background-color: var(--white);
                    color: var(--dark_color);
                    border-radius: 10px;
                    padding: 5px 16px;
                    font-size: 16px;
                    line-height: normal;
                    width: fit-content;
                }

                .imm__ai_chat__container__chat_box__chat_bot_options {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    margin-top: 8px;

                    .imm__ai_chat__container__chat_box__chat_bot_options__option {
                        border: 1px solid var(--primary_color);
                        background-color: var(--white);
                        color: var(--dark_color);
                        border-radius: 50px;
                        padding: 4px 12px;
                        font-size: 14px;
                        line-height: normal;
                        width: fit-content;
                    }
                }

                .imm__ai_chat__container__chat_box__chat_bot_time {
                    font-size: 10px;
                    color: var(--secondary_color);
                    margin-top: 4px;
                }
            }
        }
    } 

    .imm__ai_chat__container__input_box {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;
        padding: 20px 24px;
        border: 1px solid #E3E3E3;

        .imm__ai_chat__container__input_box__input {
            // placeholder color is the same as the border color
            color: #667085;
            border: none;
            background-color: transparent;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
        }

        .imm__ai_chat__container__input_box__mic,
        .imm__ai_chat__container__input_box__attachment,
        .imm__ai_chat__container__input_box__send {
            color: #667085;
            svg {
                width: 28px;
                height: 28px;
            }
        }

        .imm__ai_chat__container__input_box__btn_disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}

@media (min-width: 768px) {
    .imm__ai_chat__container {
        width: 50%;
        margin: 0 auto;

        .imm__ai_chat__container__loading {
            font-size: 32px;
        }

        .imm__ai_chat__container__error {
            font-size: 32px;
        }

        .imm__ai_chat__container__header {
            gap: 20px;
            padding: 24px 40px;

            .imm__ai_chat__container__header__action_items {
                gap: 20px;
                justify-content: center;
                .imm__ai_chat__container__header__action_items__btn {
                    padding: 12px 24px;
                    width: 200px;
                    height: 60px;
                    font-size: 18px;
                }

                .imm__ai_chat__container__header__action_items__btn_close {
                    display: none;
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            .imm__ai_chat__container__header__content {
                gap: 40px;
                .imm__ai_chat__container__header__content__logo__container {
                    width: 120px;
                    height: 120px;
                    .imm__ai_chat__container__header__content__logo {
                        width: 100px;
                        height: 100px;
                    }
                }

                .imm__ai_chat__container__header__content__text {
                    .imm__ai_chat__container__header__content__text__subtitle {
                        font-size: 16px;
                    }
                }
            }
        }

        .imm__ai_chat__container__chat_box {
            .imm__ai_chat__container__chat_box__chat_list {
                padding: 40px 24px;
                .imm__ai_chat__container__chat_box__chat_user {
                    padding-left: 24%;
                    .imm__ai_chat__container__chat_box__chat_user_metadata {
                        .imm__ai_chat__container__chat_box__chat_user_metadata__avatar {
                            display: none
                        }

                        .imm__ai_chat__container__chat_box__chat_user_metadata__name,
                        .imm__ai_chat__container__chat_box__chat_user_metadata__time {
                            font-size: 14px;
                        }
                    }

                    .imm__ai_chat__container__chat_box__chat_user_text {
                        padding: 10px 24px;
                        font-size: 18px;
                    }
                }

                .imm__ai_chat__container__chat_box__chat_bot {
                    padding-right: 24%;
                    .imm__ai_chat__container__chat_box__chat_bot_metadata {
                        .imm__ai_chat__container__chat_box__chat_bot_metadata__avatar {
                            display: block;
                            min-width: 32px;
                            min-height: 32px;
                            max-width: 32px;
                            max-height: 32px;
                        }

                        .imm__ai_chat__container__chat_box__chat_bot_metadata__name,
                        .imm__ai_chat__container__chat_box__chat_bot_metadata__time {
                            font-size: 14px;
                        }
                    }

                    .imm__ai_chat__container__chat_box__chat_bot_text {
                        padding: 10px 24px;
                        font-size: 18px;
                    }

                    .imm__ai_chat__container__chat_box__chat_bot_options {
                        .imm__ai_chat__container__chat_box__chat_bot_options__option {
                            padding: 6px 16px;
                            font-size: 16px;
                        }
                    }
                }
            }   
        }

        .imm__ai_chat__container__input_box {
            padding: 20px 40px;
            .imm__ai_chat__container__input_box__input {
                font-size: 18px;
            }

            .imm__ai_chat__container__input_box__mic,
            .imm__ai_chat__container__input_box__attachment,
            .imm__ai_chat__container__input_box__send {
                svg {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}