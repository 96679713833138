.imm__questionnaire__questions_view {
    h5 {
        margin-bottom: 20px;
    }

    .imm__questionnaire__questions_list {
        background-color: var(--white_color);
        border-radius: 14px;
        box-shadow: var(--primary_shadow_even);
        padding: 25px;

        .imm__questionnaire__repeat_group {
            background-color: var(--tertiary_color);
            padding: 25px;
            border-radius: 14px;
            margin-bottom: 30px;

            .imm__questionnaire__repeat_group_add_btn__disabled {
                color: var(--disabled_color) !important;

                .imm_sm__rgab_svg_wrap {
                    background-color: var(--disabled_color) !important;

                    svg {
                        color: var(--grey_color) !important;
                    }
                }
            }

            .imm__questionnaire__repeat_group_add_btn {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;
                margin-left: auto;

                &:hover {
                    .imm_sm__rgab_svg_wrap {
                        background-color: var(--primary_color);
                    }
                }

                .imm_sm__rgab_svg_wrap {
                    width: 24px;
                    height: 24px;
                    background-color: var(--medium_color);
                    border-radius: 50px;
                    transition: 0.3s all;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        height: 24px;
                        width: 24px;
                        min-height: 24px;
                        min-width: 24px;
                        stroke-width: 0.5;
                        color: var(--white_color);
                    }
                }
            }

            .imm__questionnaire__repeat_group_instance {
                .imm__documents__file {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    margin-bottom: 20px;

                    svg {
                        height: 20px;
                        width: 20px;
                        min-height: 20px;
                        min-width: 20px;
                        stroke-width: 1;
                    }

                    span {
                        color: var(--dark_color);
                        font-weight: 500;
                    }

                    .attach_file_btn {
                        padding: 16px 16px;
                        gap: 8px;

                        svg {
                            color: var(--primary_color);
                        }
                    }

                    .uploaded_file__container {
                        display: flex;
                        gap: 0px;
                        align-items: center;

                        .uploaded_file__name {
                            text-decoration: underline;
                        }

                        svg {
                            height: 16px;
                            width: 16px;
                            min-height: 16px;
                            min-width: 16px;
                        }

                        .view_file_btn {
                            gap: 0;
                            margin-right: 4px;

                            span {
                                font-weight: 400;
                                font-size: 14px;
                                color: var(--info_color);
                                text-decoration: underline;
                            }

                            svg {
                                color: var(--primary_color);
                            }
                        }

                        .remove_file_btn {
                            margin-left: 12px;
                            color: var(--error_color);
                        }
                    }

                    .uploading_file__container {
                        display: flex;
                        gap: 4px;
                        align-items: center;

                        span {
                            color: var(--dark_grey_color);
                        }

                        button {
                            color: var(--error_color);
                            font-weight: 500;
                            font-size: 14px;
                            margin-left: 8px;
                            text-decoration: underline;
                        }
                    }

                }

                .imm__questionnaire__repeat_group_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h5 {
                        margin-bottom: 0;
                        padding-left: 0;
                    }

                    .imm__questionnaire__repeat_group_remove_btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            height: 20px;
                            width: 20px;
                            min-height: 20px;
                            min-width: 20px;
                            stroke-width: 1;
                        }
                    }
                }
            }
        }
    }

    .imm__questionnaire__form_action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-top: 30px;

        .imm__regular__btn {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__questionnaire__questions_view {
        padding: 15px;

        h5 {
            margin-bottom: 15px;
            padding-left: 15px;
        }

        .imm__questionnaire__questions_list {
            padding: 15px;

            .imm__questionnaire__repeat_group {
                padding: 15px;

                .imm__questionnaire__repeat_group {
                    padding: 0 !important;
                }
            }
        }
    }
}

.imm__questionnaire_question_template {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .imm__qqt__contents {
        width: 100%;
        padding-bottom: 20px;

        .imm__qqt__question_title {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            button {
                color: var(--medium_color);

                &:hover {
                    color: var(--primary_color);
                }

                svg {
                    height: 20px;
                    width: 20px;
                    min-height: 20px;
                    min-width: 20px;
                }
            }
        }
    }
}

.imm__qqt__primary {}

.imm__qqt__secondary,
.imm__qqt__tertiary {

    .imm__qqt__contents {
        border-left: 5px dashed var(--medium_color);
        padding-left: 20px;
    }
}

.imm__qqt__secondary {
    padding-left: 15px;
}

.imm__qqt__tertiary {
    padding-left: 55px;
}

@media #{map-get($media_queries, mobile)} {
    .imm__qqt__secondary {
        padding-left: 10px;
    }

    .imm__qqt__tertiary {
        padding-left: 30px;
    }

    .imm__qqt__secondary,
    .imm__qqt__tertiary {
        .imm__qqt__contents {
            border-left-width: 3px;
            padding-left: 10px;
        }
    }
}

.imm__questionnaire__text_question__readonly {
    .imm__questionnaire__tq__input_container {
        background-color: var(--light_grey_color) !important;

        input {
            color: var(--disabled_color) !important;

            &::placeholder {
                color: var(--disabled_color) !important;
            }
        }
    }
}

.imm__questionnaire__text_question {
    .imm__questionnaire__tq__input_container {
        border: 1px solid var(--light_grey_color);
        border-radius: 8px;
        transition: 0.3s all;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        padding: 7px 12px;
        background-color: var(--white_color);

        .input_group__pre_sec {
            padding-right: 10px;
        }

        input {
            background-color: transparent;
            font-size: 14px;
            border-bottom: none !important;

            &::placeholder {
                color: var(--grey_color);
            }

            &:read-only {
                user-select: none;
                cursor: default;
            }
        }
    }
}

.imm__questionnaire__boolean_question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;

    .imm__questionnaire__select_btn {
        width: 100%;
        border: 1px solid var(--light_grey_color);
        background-color: var(--white_color);
        border-radius: 8px;
        height: 40px;
        font-weight: 500;

        &:hover {
            border-color: var(--medium_color);
        }
    }

    .imm__questionnaire__select_btn__selected {
        background-color: var(--medium_color);
        font-weight: 600;
        box-shadow: var(--primary_shadow_offset_bottom);
    }
}

.imm__questionnaire__dropdown__opened {

    .imm__questionnaire__dqd__btn,
    .imm__questionnaire__sqd__input_container {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.imm__questionnaire__dropdown {
    position: relative;

    .imm__questionnaire__dropdown__content {
        width: 100%;
        box-shadow: var(--primary_shadow_offset_bottom);
        position: absolute;
        top: 100%;
        left: 0;
        z-index: map-get($z_indexes, dropdown);
        background-color: var(--white_color);
        max-height: 250px;
        overflow: hidden auto;
        border-radius: 0 0 8px 8px;
        border: 1px solid var(--light_grey_color);

        button {
            justify-content: flex-start;
            text-align: start;
            padding: 5px 10px;
            width: 100%;
            border-bottom: 1px solid var(--light_grey_color);
            font-size: 14px;

            &:hover {
                background-color: var(--primary_color_lightest);
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        .imm__questionnaire__dropdown__option__readonly {
            background-color: #f4f4f4 !important;
            color: var(--grey_color) !important;
            cursor: default !important;
        }
    }
}

.imm__questionnaire__search_question__readonly {
    .imm__questionnaire__sqd__input_container {
        background-color: var(--light_grey_color) !important;

        input {
            &::placeholder {
                color: var(--disabled_color) !important;
            }
        }

        svg {
            color: var(--grey_color) !important;
        }
    }
}

.imm__questionnaire__search_question {
    width: 100%;

    .imm__questionnaire__sqd__input_container {
        width: 100%;
        padding: 7px 16px;
        border: 1px solid var(--light_grey_color);
        background-color: var(--white_color);
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        input {
            background-color: transparent;
            border-bottom: none !important;

            &::placeholder {
                color: var(--secondary_dark_color);
            }
        }

        svg {
            height: 12px;
            width: 12px;
            min-height: 12px;
            min-width: 12px;
            color: var(--primary_color);
        }
    }
}

.imm__questionnaire__dropdown_question {
    width: 100%;

    .imm__questionnaire__dqd__btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        border: 1px solid var(--light_grey_color);
        background-color: var(--white_color);
        border-radius: 8px;

        .imm__questionnaire__dq__answer {
            text-align: start;
        }

        .imm__questionnaire__dq__placeholder {
            color: var(--secondary_dark_color);
        }

        svg {
            height: 12px;
            width: 12px;
            min-height: 12px;
            min-width: 12px;
            stroke-width: 1;
            color: var(--primary_color);
        }
    }
}

.imm__questionnaire__calender_question {
    width: 100%;

    .imm__questionnaire__cq__container__opened {
        .imm__questionnaire__cq__input__cont {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    .imm__questionnaire__cq__container {
        position: relative;

        .imm__questionnaire__cq__input__cont__readonly {
            background-color: var(--light_grey_color) !important;
            color: var(--disabled_color) !important;

            svg {
                color: var(--disabled_color) !important;
            }
        }

        .imm__questionnaire__cq__input__cont {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;
            border: 1px solid var(--light_grey_color);
            background-color: var(--white_color);
            border-radius: 8px;

            input {
                background-color: transparent;
                border-bottom: none !important;

                &::placeholder {
                    color: var(--grey_color);
                }

                &:read-only {
                    user-select: none;
                    cursor: default;
                }
            }

            .imm__questionnaire__cq__input__cont__span {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--grey_color) !important;
                    transition: 0.3s all;
                    cursor: pointer;

                    &:hover {
                        color: var(--dark_grey_color) !important;
                    }

                    svg {
                        height: 15px;
                        width: 15px;
                        min-height: 15px;
                        min-width: 15px;
                    }
                }

                .calender-ico6537 {
                    height: 18px;
                    width: 18px;
                    min-height: 18px;
                    min-width: 18px;
                    color: var(--primary_color);
                }
            }
        }

        .imm__questionnaire__cq__calender {
            position: absolute;
            top: 100%;
            width: 100%;
            border: 1px solid var(--light_grey_color);
            border-radius: 0 0 8px 8px;
            box-shadow: var(--primary_shadow_offset_bottom);
            background-color: var(--white_color);
            z-index: map-get($z_indexes, dropdown);
            overflow: hidden;
        }
    }
}