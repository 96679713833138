.imm__onbording__modal_overlay {
    --imm__onboardings_modal__max_width: 530px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__onbording__modal_overlay {
        --imm__onboardings_modal__max_width: 100%;
    }
}

.ReactModal__Content--after-open.imm__onbording__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__onbording__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__onboardings_modal__max_width);
}

.imm__onbording__modal_content {
    background-color: var(--tertiary_color) !important;
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 10px;
    width: 100%;

    .imm__onbording_inner_wrap {
        position: relative;

        .close_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary_color);
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;

            &:hover {
                color: var(--primary_hover_color);
            }

            svg {
                width: 35px;
                height: 35px;
                min-width: 35px;
                max-width: 35px;
                stroke-width: 0.5;
            }
        }

        .imm__onbording__slider {
            position: relative;
            width: 100%;
            margin: auto;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 55px 35px 25px 35px;

            .imm__onbording__slider_items {
                display: flex;
                transition: transform 0.5s ease-in-out;
                width: 100%;

                .imm__onbording__slider_item {
                    background-color: var(--primary_color_light);
                    min-width: 100%;
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;

                    &.active {
                        opacity: 1;
                    }

                    .imm__onbording__slider_item__title {
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 34px;
                        letter-spacing: 0.5px;
                        color: var(--primary_color);
                        text-align: center;
                    }
                }

            }
        }

        .imm__onbording__slider_arrow {
            background: none;
            border: none;
            font-size: 2rem;
            cursor: pointer;
            position: absolute;
            z-index: 10;

            &.arrow_left {
                left: 0;
            }

            &.arrow_right {
                right: 0;
            }
        }

        .imm__onbording__score_progress {
            margin: 30px 0 0 0;
            text-align: center;

            .imm__onboarding__score_progress__progressbar {
                width: 228px;
                height: 228px;
                margin: auto;

                p {
                    font-size: 14px;
                    color: #2C2C2C;
                    padding-bottom: 20px;
                }

                span {
                    font-weight: 700;
                    font-size: 69px;
                    color: #39B54A;
                }
            }

            .imm__onbording__score_progress__p1 {
                font-size: 32px;
                color: var(--success_color);
                font-weight: 700;
                padding: 20px 0;
            }

            .imm__onbording__score_progress__p2 {
                font-size: 36px;
                color: var(--error_color);
                font-weight: 700;
                padding-top: 10px;
                padding-bottom: 35px;
            }
        }

        .imm__onbording__score_matrix {
            img {
                max-width: 100%;
                margin-top: 30px;
                margin-bottom: 65px;
            }
        }

        .imm__onbording__hypo_preview {
            text-align: center;

            .imm__onbording__score_hypo_card {
                --difficulty_color: var(--primary_color);

                margin-bottom: 15px;
                padding: 10px 20px;
                background-color: var(--white_color);
                border-radius: 14px;
                box-shadow: var(--primary_shadow_even);
                text-align: left;
                position: relative;

                .no_bs {
                    position: absolute;
                    right: 0px;
                    top: 5px;
                }

                &.hard {
                    border: solid 1px var(--error_color);
                }

                &.easy {
                    border: solid 1px var(--success_color);
                }

                .imm__shc__d__easier {
                    --difficulty_color: var(--success_color);

                    .imm__shc__dots {

                        span:nth-child(1),
                        span:nth-child(2),
                        span:nth-child(3) {
                            background-color: var(--difficulty_color);
                        }
                    }
                }

                .imm__shc__d__medium {
                    --difficulty_color: var(--warning_color);

                    .imm__shc__dots {

                        span:nth-child(1),
                        span:nth-child(2) {
                            background-color: var(--difficulty_color);
                        }
                    }
                }

                .imm__shc__d__hard {
                    --difficulty_color: var(--error_color);

                    .imm__shc__dots {

                        span:nth-child(1) {
                            background-color: var(--difficulty_color);
                        }
                    }
                }

                .imm__shc__top {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;
                }

                .imm__shc__score_success {
                    color: var(--success_color);
                    font-weight: 600;
                    font-size: 14px;
                }

                .imm__shc__score_hard {
                    color: var(--error_color);
                    font-weight: 600;
                    font-size: 14px;
                }

                .imm__shc__difficulty {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    border: 1px solid var(--difficulty_color);
                    border-radius: 30px;
                    padding: 2px 14px;
                    width: fit-content;

                    .imm__shc__dots {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 2px;

                        span {
                            border: 1px solid var(--difficulty_color);
                            height: 5px;
                            width: 5px;
                            border-radius: 10px;
                        }
                    }

                    .imm__shc__difficulty_title {
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 12px;
                        letter-spacing: 0.5px;
                        color: var(--difficulty_color);
                    }
                }

                .imm__shc__hypo_content {
                    margin-top: 10px;

                    .imm__shc__hypo_content_bullet {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 10px;

                        .imm__shc__hypo_content__dot {
                            margin-top: 8px;
                            background-color: var(--dark_color);
                            width: 5px;
                            height: 5px;
                            border-radius: 10px;
                        }

                        span {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .imm__onbording__notification_preview {
            text-align: center;

            .imm__home_page_navigation_card {
                text-align: left;
                padding: 10px 20px;

                .imm__hpnc__left {
                    padding: 0;
                }
            }
        }

        .imm__onbording__program_preview {
            text-align: center;

            .imm__onbording__program_card {
                text-align: left;
                border-radius: 14px;
                padding: 18px;
                background-color: var(--white_color);
                box-shadow: var(--primary_shadow_even);
                border: 2px solid var(--success_color);

                .imm__pc__header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 15px;

                    .imm__pc__heart {
                        cursor: pointer;

                        .heart_fill {
                            color: var(--heart_red_color);

                            &:hover {
                                color: var(--heart_red_hover_color);
                            }
                        }

                        svg {
                            width: 32px;
                            height: 29px;
                            min-width: 32px;
                            min-height: 29px;
                        }
                    }
                }

                .imm__pc__content {
                    display: flex;
                    justify-content: flex-start;
                    padding-top: 10px;

                    .imm__pc__description {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .ReactModal__Content--after-open.imm__onbording__modal_dialog {
        top: 0;
    }

    .imm__onbording__modal_overlay {
        background-color: var(--tertiary_color);
    }

    .imm__onbording__modal_dialog {
        padding: 0;
    }

    .imm__onbording__modal_content {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
    }
}