.imm__hpnc__status__available {
    border-color: var(--success_color) !important;
}

.imm__hpnc__status__unavailable {
    border-color: var(--error_color) !important;
}

.imm__hpnc__status__disabled {
    background-color: #F5F5F5 !important;
    cursor: default !important;

    h5,
    p {
        color: #9A9A9A !important;
    }
}

.imm__home_page_navigation_card {
    position: relative;
    cursor: pointer;
    background-color: var(--white_color);
    box-shadow: var(--primary_shadow_even);
    border: 1px solid transparent;
    border-radius: 14px;
    padding: 14px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;

    .imm__hpnc__status_icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
    }

    .imm__hpnc__left {
        padding: 0 60px;

        img {
            max-width: 35px;
            min-width: 35px;
        }
    }

    .imm__hpnc__right {}
}

@media #{map-get($media_queries, mobile)} {
    .imm__home_page_navigation_card {
        .imm__hpnc__status_icon {
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
        }

        .imm__hpnc__left {
            padding: 0;
        }
    }
}

.imm__status_card {
    background-color: var(--white_color);
    box-shadow: var(--primary_shadow_even);
    border-radius: 14px;
    padding: 24px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;

    .imm__sc__left {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 60px;

        svg {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
        }
    }

    .imm__sc__right {
        h5 {
            a {
                display: inline;
                text-decoration: underline;
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__status_card {
        .imm__sc__left {
            padding: 0;

            svg {
                width: 25px;
                height: 25px;
                min-width: 25px;
                min-height: 25px;
            }
        }

        .imm__sc__right {
            h5 {
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

.imm__notification_card {
    width: 100%;
    background-color: var(--white_color);
    box-shadow: var(--primary_shadow_even);
    border-radius: 14px;
    padding: 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    .imm_nc__read_identifier {
        display: flex;
        padding-top: 8px;

        svg {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            color: var(--primary_color);
            stroke-width: 1px;
        }
    }

    .imm_nc__content {
        width: 100%;

        .date_cont {
            color: #7F7F7F;
            font-size: 14px;
            padding-top: 5px;
        }

        .descr_cont {
            padding-top: 10px;
            padding-bottom: 10px;

            p {
                font-size: 15px;
                line-height: normal;
            }
        }

        .recommended_action {
            padding-top: 10px;
            border-top: 0.5px solid #82a2e391;
        }
    }

    .imm_nc__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        padding-left: 10px;

        .imm__nc__fav_btn {
            color: var(--primary_color);

            svg {
                width: 32px;
                height: 32px;
                min-width: 32px;
                min-height: 32px;
            }

            &:hover {
                color: #FF4040;
            }
        }

        .imm__nc__fav_btn__active {
            color: #FF4040;
        }

        .imm__nc__action_btn {
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            background-color: var(--primary_color);
            color: var(--white_color);
            border-radius: 50%;

            svg {
                stroke-width: 1px;
            }

            &:hover {
                background-color: var(--primary_hover_color);
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__notification_card {
        padding: 16px;
        gap: 10px;

        .imm_nc__read_identifier {
            padding-top: 5px;
        }

        .imm_nc__content {
            .date_cont {
                font-size: 12px;
                padding-top: 0;
            }

            .descr_cont {
                padding-top: 5px;
                padding-bottom: 5px;

                p {
                    font-size: 14px;
                }
            }

            .recommended_action {
                padding-top: 5px;
            }
        }

        .imm_nc__action {
            padding-left: 5px;

            .imm__nc__fav_btn {
                svg {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    min-height: 30px;
                }
            }

            .imm__nc__fav_btn__active {
                color: #FF4040;
            }

            .imm__nc__action_btn {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
            }
        }
    }
}

.imm__draws_card {
    width: 100%;
    background-color: var(--white_color);
    box-shadow: var(--primary_shadow_even);
    border-radius: 14px;
    padding: 24px;
    margin-bottom: 20px;

    .imm__dc__content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .imm__dc__detail {
            .imm__dc__detail_date {
                color: var(--secondary_color);
                font-weight: 600;
                font-style: italic;
            }

            .imm__dc__detail_type {
                font-weight: 500;
            }
        }

        .imm__dc__score_invite {
            display: flex;

            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .imm__dc__pill {
                display: flex;
                justify-content: center;
                align-items: center;

                padding: 2px 14px;
                border-radius: 25px;
                background-color: var(--medium_color);
                box-shadow: 0px 0px 16px 0px #3e62ac1a;

                .imm__dc__pill_text {
                    font-weight: 400;
                }
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__draws_card {
        padding: 16px 24px;
        margin-bottom: 16px;

        .imm__dc__content {
            gap: 8px;

            .imm__dc__score_invite {
                gap: 8px;
            }
        }
    }
}

.imm__program_card {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 14px;
    row-gap: 12px;
    padding: 24px;
    margin-bottom: 20px;
    background-color: var(--white_color);
    box-shadow: var(--primary_shadow_even);
    position: relative;
    overflow: hidden;

    &:nth-child(1) {
        margin-top: 32px;
    }

    .imm__shc__blurry_film {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(7px);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .imm__shc__blurry_film__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            svg {
                width: 32px;
                height: 32px;
                min-width: 32px;
                min-height: 32px;
            }

            button {
                background-color: var(--primary_color);
                color: var(--white_color);
                border-radius: 50px;
                padding: 5px 20px;
                font-size: 12px;
                font-weight: 600;

                &:hover {
                    background-color: var(--primary_hover_color);
                }
            }
        }
    }

    .imm__pc__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .imm__pc__heading {
            font-weight: 500;
        }

        .imm__pc__heart {
            cursor: pointer;

            .heart_fill {
                color: var(--heart_red_color);

                &:hover {
                    color: var(--heart_red_hover_color);
                }
            }

            svg {
                width: 32px;
                height: 29px;
                min-width: 32px;
                min-height: 29px;
            }
        }
    }

    .imm__pc__content {
        display: flex;
        justify-content: flex-start;

        .imm__pc__description {
            font-weight: 400;
        }
    }

    .imm__pc__action {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .imm__pc__action_link {
            color: var(--primary_color);

            h6 {
                font-weight: 400;
                text-decoration: underline;
            }
        }

        .imm__btn__primary {
            border-radius: 40px;
            padding: 0 10px;
            height: auto;
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                background-color: var(--primary_hover_color);
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__program_card {
        padding: 16px 24px;
        gap: 8px;

        &:nth-child(1) {
            margin-top: 24px;
        }
    }
}