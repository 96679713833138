.imm__comming_soon__page_view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .imm__comming_soon__title,
    .imm__comming_soon__text {
        font-style: normal;
        line-height: normal;
        text-align: center;
    }

    .imm__comming_soon__title {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.688px;
    }

    .imm__comming_soon__text {
        font-size: 26px;
        font-weight: 400;
        letter-spacing: 0.813px;
        max-width: 443px;
    }

    .imm__comming_soon__logo {
        max-width: 517px;
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__comming_soon__page_view {
        .imm__comming_soon__title {
            font-size: 18px;
            letter-spacing: 0.563px;
        }

        .imm__comming_soon__text {
            font-size: 24px;
            letter-spacing: 0.75px;
            max-width: 294px;
        }

        .imm__comming_soon__logo {
            max-width: 357px;
        }
    }
}

@media only screen and (max-width: 575.98px) {
    .imm__comming_soon__page_view {
        .imm__comming_soon__title {
            font-size: 14px;
            letter-spacing: 0.563px;
        }

        .imm__comming_soon__text {
            font-size: 16px;
            letter-spacing: 0.75px;
            max-width: 280px;
        }

        .imm__comming_soon__logo {
            max-width: 250px;
        }
    }
}