.imm__program_fitlers_container {
    width: 100%;
    display: flex;
    gap: 12px;
    padding: 10px 20px 10px 20px;
    margin: 20px 0;
    color: #37334D;
    background-color: var(--white_color);
    text-align: 'left';
    font-style: italic;
    position: relative;
    border-radius: 4px;
    cursor: pointer;

    .imm__program_fitlers_count {
        position: absolute;
        background: #39B54A;
        color: #fff;
        width: 17px;
        height: 17px;
        border-radius: 100%;
        text-align: center;
        font-size: 9px;
        left: 30px;
        line-height: 18px;  
    }

    .imm__program_fitlers_icon_btn {
        img {
            margin-right: 10px;
            vertical-align: middle;
        }
    }
}