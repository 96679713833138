.imm__two_factor_authentication__modal_overlay {
    --imm__two_factor_authentication_modal__max_width: 500px;
    --imm__two_factor_authentication_modal__max_height: 800px;
}

@media only screen and (max-width: 500px) {
    .imm__two_factor_authentication__modal_overlay {
        --imm__two_factor_authentication_modal__max_width: 100%;
        --imm__two_factor_authentication_modal__max_height: 100%;
    }
}

.ReactModal__Content--after-open.imm__two_factor_authentication__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}
@media only screen and (max-width: 932px) {
    .ReactModal__Content--after-open.imm__two_factor_authentication__modal_dialog {
        top: 0;
        padding: 0;
        border-radius: 0;
        height: 100%;
        background-color: var(--white_color);
    }
}

.imm__two_factor_authentication__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__two_factor_authentication_modal__max_width);
    max-height: var(--imm__two_factor_authentication_modal__max_height);
}

.imm__two_factor_authentication__modal_content {
    width: 100%;
    height: 100%;
    background-color: var(--white_color);
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 0;

    .imm__two_factor_authentication_header {
        display: flex;
        flex-direction: column;
        align-items: center;

        .imm__two_factor_authentication_close_div {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            .close_btn {
                svg {
                    width: 28px;
                    height: 28px;
                    stroke-width: 0.5;
                }
            }
        }

        .imm__two_factor_authentication_header_title {
            text-align: center;
            font-weight: 600;
        }

        .imm__two_factor_authentication_header_subtitle {
            margin-top: 10px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
            color: var(--text_color);
            padding: 0 24px;
        }
    }

    .imm__two_factor_authentication_body {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0px 24px;
        max-height: 600px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;


        .authenticator_btn {
            width: 42px;   
            height: 42px;
            border-radius: 8px;
            border: 1px solid #CFCFCF;
            box-shadow: 0 0 10px 0 #00000044;
            img {
                width: 100%;
                height: 100%;
            } 
            svg {
                display: none;
            }
        }

        .authenticator_btn_registered {
            position: relative;
            border: 2px solid var(--success_color);
            // make the button disabled
            pointer-events: none;
            // opacity: 0.5;

            img {
                filter: grayscale(0);
            }

            svg {
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                background-color: var(--success_color);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--white_color);
            }
        }

        .authenticator_section {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .authenticator_btns {
                display: flex;
                gap:24px;
                justify-content: center;

            }
        }

        .authenticator_qr_code_container {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            padding: 0 24px;

            h6{
                text-align: center;
            }
            .authenticator_btn {
                width: 56px;
                height: 56px;
            }
            .authenticator_qr_code_box {
                width: 50%;
                height: auto;
                padding: 2%;
                border-radius: 8px;
                box-shadow: 0 0 10px 0 #00000044;
                img {
                    width: 100%;
                    height: auto;
                }
            }

            .authenticator_input_box {
                width: 100%;
                display: flex;
                gap:12px;
                align-items: center;
                input {
                    width: 100%;
                    padding: 8px 12px;
                    border-radius: 8px;
                    border: 1px solid #CFCFCF;
                    font-size: 12px;
                    font-weight: 400;
                    color: var(--text_color);
                }

                .get_code_btn {
                    min-width: 100px;
                    flex:1;
                    background-color: var(--primary_color);
                    padding: 8px;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--white_color);
                }
            }

            .expires_in_text {
                font-size: 12px;
                font-weight: 400;
                color: var(--dark_grey_color);
            }
        }
    }

    .imm__two_factor_authentication_footer {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding-bottom: 20px;

        .submit_btn {
            background-color: var(--primary_color);
            padding: 12px;
            border-radius: 40px;
            font-size: 16px;
            font-weight: 600;
            color: var(--white_color);
            width: 80%;
        }

        .another_method_box {
            display: flex;
            justify-content: center;
            button {
                font-size: 14px;
                font-weight: 400;
                color: var(--text_color);
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                    color: var(--primary_color);
                }
            }
        }

        .error_box {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: var(--error_color);
            font-size: 14px;
            font-weight: 400;
        }

        .success_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 24px;
            gap: 4px;

            .success_message {
                color: var(--success_color);
                font-size: 16px;
                font-weight: 500;
            }

            .success_disclaimer {
                font-size: 12px;
                color: var(--dark_grey_color);
                font-weight: 400;
                line-height: normal;
            }

            .success_action_btns {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                .close_btn {
                    min-width: 80px;
                    background-color: var(--primary_color);
                    padding: 8px 16px;
                    border-radius: 40px;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--white_color);
                    cursor: pointer;
                }
            }
        }
    }

}

@media screen and (max-width: 932px) {
    .imm__two_factor_authentication__modal_content {
        border-radius: 0;
        background-color: white;

        .imm__two_factor_authentication_header {
            .imm__two_factor_authentication_close_div {
                .close_btn {
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .imm__two_factor_authentication_body {
            padding: 0;
            max-height: 600px;
            overflow-y: auto;
            .authenticator_section {
                .authenticator_btns {
                    flex-wrap: wrap;
                    gap: 12px;
                }
            }

            .authenticator_qr_code_container {
                .authenticator_btn {
                    width: 42px;
                    height: 42px;
                }

                .authenticator_qr_code_box {
                    width: 40%;
                    padding: 2%;
                }
            }
        }

        .imm__two_factor_authentication_footer {
            padding-bottom: 0;

            .submit_btn {
                padding: 12px 0;
            }

            .another_method_box {
                padding: 12px 0;
            }

            .error_box {
                padding: 6px 0;
            }

            .success_box {
                padding: 0 16px;
                gap: 8px;
                .success_message {
                    font-size: 14px;
                    text-align: center;
                }
                .success_disclaimer {
                    font-size: 12px;
                    padding-left: 8px;
                }
                .success_action_btns {
                    display: none;
                    .close_btn {
                        padding: 8px 0;
                    }
                }
            }
        }
    }    
}