::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    background: var(--medium_color);
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background: var(--primary_color);
    border-radius: 3px;
}