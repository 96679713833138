.imm__home__page_view {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__create_spouse_view {
        h5 {
            padding-bottom: 10px;
        }

        form {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--disabled_color);
            border-radius: 8px;
            background-color: var(--white_color);
            padding-left: 15px;

            button {
                min-width: max-content;
                background-color: var(--primary_color);
                color: var(--white_color);
                padding: 10px 15px;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border: 1px solid var(--primary_color);

                &:hover {
                    background-color: var(--primary_hover_color);
                }
            }
        }
    }

    .imm__home_navigation_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }
}