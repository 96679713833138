.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

@media #{map-get($media_queries, md)} {
    .container {
        max-width: map-get($container_max_widths, md);
    }
}

@media #{map-get($media_queries, lg)} {
    .container {
        max-width: map-get($container_max_widths, lg);
    }
}

@media #{map-get($media_queries, xl)} {
    .container {
        max-width: map-get($container_max_widths, xl);
    }
}

@media #{map-get($media_queries, xxl)} {
    .container {
        max-width: map-get($container_max_widths, xxl);
    }
}

@media #{map-get($media_queries, xxxl)} {
    .container {
        max-width: map-get($container_max_widths, xxxl);
    }
}

@media #{map-get($media_queries, container_max)} {
    .container {
        max-width: map-get($container_max_widths, xxxl);
    }
}

.container_fluid {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@for $j from 1 through 12 {
    .col-#{$j} {
        --eq1: calc(100% / 12);
        --calc: calc(var(--eq1) * #{$j});

        width: 100%;
        padding: 0 15px;
        flex: 0 0 var(--calc);
        max-width: var(--calc);
    }
}

.no_gutters {
    margin-right: 0;
    margin-left: 0;

    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.display_inline {
    display: inline !important;
}

.display_block {
    display: block !important;
}

.display_flex {
    display: flex !important;
}

.flex_dir_column {
    flex-direction: column !important;
}

.display_flex_center {
    align-items: center !important;
    justify-content: center !important;
}

.display_flex_center_horiz {
    justify-content: center !important;
}

.display_flex_center_vert {
    align-items: center !important;
}

.display_flex_start {
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.display_flex_start_horiz {
    justify-content: flex-start !important;
}

.display_flex_start_vert {
    align-items: flex-start !important;
}

.display_flex_end {
    justify-content: flex-end !important;
    align-items: flex-end !important;
}

.display_flex_end_horiz {
    justify-content: flex-end !important;
}

.display_flex_end_vert {
    align-items: flex-end !important;
}