.imm__header {
    position: fixed;
    top: 0;
    background-color: var(--tertiary_color);
    box-shadow: var(--primary_shadow_offset_bottom);
    padding: 0 40px;
    z-index: map-get($z_indexes, sticky);
    overflow-x: hidden;
    height: fit-content;

    .header__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        .header_content__left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .imm__header__logo {
                display: flex;
                justify-content: center;
                align-items: center;
                width: max-content;
                height: 30px;

                img {
                    width: auto !important;
                }
            }
        }

        .header_content__middle {
            display: flex;
            padding: 0;
            margin-left: 8px;

            .imm__header__menu__btn {
                padding-top: 4px;
                font-size: 16px;
                font-weight: 600;
                position: relative;
                width: 100px;

                .imm__header__menu__btn__online_logo {
                    position: absolute;
                    top: 4px;
                    right: 2px;

                    border-radius:  50%;
                    min-width: 8px;
                    min-height: 8px;
                    background-color: var(--secondary_color);
                }
            }
            
        }

        .header_content__right {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .imm__header__menu {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .imm__header__menu__btn {
                    color: var(--primary_color);
                    padding: 21px 20px;
                    position: relative;

                    &.active {
                        font-weight: 600;

                        &:hover {
                            color: var(--primary_color);
                        }
                    }

                    .imm__header__menu__btn__online_logo {
                        position: absolute;
                        top: 24px;
                        right: 8px;

                        border-radius:  50%;
                        min-width: 10px;
                        min-height: 10px;
                        background-color: var(--secondary_color);
                    }

                    &:hover {
                        color: var(--secondary_color);
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            .hamburger_btn {
                width: 30px;
                height: 30px;
                margin: 10px 0;
                position: relative;
                transform: rotate(0deg);
                transition: .5s ease-in-out;
                cursor: pointer;

                span {
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background: var(--primary_color);
                    border-radius: 20px;
                    opacity: 1;
                    left: 0;
                    transform: rotate(0deg);
                    transition: .25s ease-in-out;

                    &:nth-child(1) {
                        top: 6px;
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        top: 14px;
                    }

                    &:nth-child(4) {
                        top: 22px;
                    }
                }

                &.hamburger_btn__opened {
                    span {
                        &:nth-child(1) {
                            top: 14px;
                            width: 0%;
                            left: 50%;
                        }

                        &:nth-child(2) {
                            transform: rotate(45deg);
                        }

                        &:nth-child(3) {
                            transform: rotate(-45deg);
                        }

                        &:nth-child(4) {
                            top: 14px;
                            width: 0%;
                            left: 50%;
                        }
                    }
                }
            }
        }
    }
}

.hamburger_menu {
    position: fixed;
    left: 0;
    width: 100%;
    background-color: var(--tertiary_color);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    z-index: map-get($z_indexes, 'ham');
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px 15px 20px;

    button {
        color: var(--primary_color);
    }

    .ham_top_nav,
    .ham_bottom_nav {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        position: relative;

        span.badge {
            top: -10px;
            right: -40px;
        }
    }

    &.hamburger_menu__opened {
        visibility: visible;
        opacity: 1;
    }
}

.imm__header__no_shadow {
    box-shadow: none;
}

span.badge {
    background-color: var(--heart_red_color);
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    position: absolute;
    top: 10px;
    right: -10px;
    border: 2px solid var(--white_color);
    font-size: 14px;
    font-weight: bold;
}

@media #{map-get($media_queries, mobile)} {
    .imm__header {
        padding: 5px 15px 0 15px;

        .imm__header__logo {
            height: 27px !important;
        }
    }
}