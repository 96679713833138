.imm__packages_component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    .imm__packages_component__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 0;

        .imm__packages_component__plan_switch {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #F4F7FD;
            border-radius: 20px;
            padding: 4px;
            width: fit-content;
            gap: 4px;

            .imm__packages_component__plan_switch__btn {
                font-weight: 500;
                font-size: 16px;
                padding: 10px 22px;
                border-radius: 16px;

                &:hover {
                    background-color: #dee6f7;
                }
            }

            .imm__packages_component__plan_switch__btn__active {
                background-color: var(--primary_color);
                color: var(--white_color);

                &:hover {
                    background-color: var(--primary_color);
                }
            }
        }

        .imm__packages_component__header__note {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding-top: 10px;

            img {
                width: 25px;
            }
        }
    }

    .imm__packages_component__loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;

        .imm__list_loader {
            width: 35px;
            height: 35px;
        }

        h4 {
            padding-top: 10px;
        }
    }

    .imm__packages_component__plans {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 20px;
        padding-top: 20px;
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__packages_component {
        .imm__packages_component__header {
            padding: 15px 0;

            .imm__packages_component__header__note {
                max-width: 85%;
            }
        }
    }
}

.imm__packages_component__plans__plan_card__four_seasons {
    width: 1160px !important;
    min-width: 1160px !important;

    .imm__packages_component__plans__plan_card__body {
        display: flex;
        gap: 30px;

        .imm__packages_component__plans__plan_card__body__column {
            width: calc(100% / 3);
        }

        .imm__pcppcp__description {
            font-size: 13px !important;
            padding-bottom: 20px;
        }

        .imm__packages_component__plans__plan_card__features__container {
            padding-top: 10px !important;
        }

        .imm__pcppcf__feature_descr {
            p {
                color: #6C6965;

                &:first-child {
                    font-size: 13.5px;
                }

                &:last-child {
                    font-size: 10px;
                }
            }
        }
    }
}

.imm__pcppc__recommended {
    border-color: var(--secondary_color) !important;

    .imm__packages_component__plans__plan_card__header {
        background-color: var(--secondary_color) !important;
    }
}

.imm__pcppc__inactive {
    background-color: #EBEBEB !important;

    .imm__pcppcf__title {
        color: #6C6965 !important;
    }

    .imm__pcppcf__features__feature {
        p {
            color: #6C6965 !important;
        }
    }
}

.imm__pcppc__disabled {
    background-color: #F5F5F5 !important;
    border-color: #F5F5F5 !important;

    .imm__packages_component__plans__plan_card__header {
        background-color: #707070 !important;

        span {
            display: none;
        }
    }

    .imm__pcppcp__price__inactive_text,
    .imm__pcppcp__price__main,
    .imm__pcppcp__price__sub,
    .imm__pcppcp__description,
    .imm__pcppcp__bonus_text,
    .imm__pcppcf__title,
    .imm__packages_component__plans__plan_card__bonus_text,
    .imm__pcppcf__features__feature>p {
        color: #6C6965 !important;
    }

    .imm__pcppcb__disabled {
        background-color: #cfcfcf !important;
        border-color: #F5F5F5 !important;
        cursor: default !important;

        span {
            color: #6C6965 !important;
        }

        img {
            filter: grayscale(100%);
        }
    }
}

.imm__packages_component__plans__plan_card {
    width: 275px;
    min-width: 275px;
    max-width: 100%;
    border: 1px solid var(--primary_color);
    border-radius: 24px;
    overflow: hidden;
    height: max-content;
    background-color: var(--white_color);

    .imm__packages_component__plans__plan_card__header {
        background-color: var(--primary_color);
        position: relative;
        padding: 16px 20px;

        p {
            color: var(--white_color);
            font-size: 21px;
            font-weight: 600;
        }

        span {
            position: absolute;
            right: 14px;
            top: 14px;
            background-color: var(--white_color);
            font-size: 13px;
            font-weight: 600;
            color: var(--primary_color);
            padding: 3.5px 7px;
            border-radius: 8px;
        }
    }

    .imm__packages_component__plans__plan_card__body {
        padding: 25px 20px;

        .imm__packages_component__plans__plan_card__price {
            min-height: 120px;
            padding-top: 10px;

            .imm__pcppcp__price__inactive_text {
                font-size: 24px;
                font-weight: 700;
                color: var(--dark_color);
            }

            .imm__pcppcp__price {
                .imm__pcppcp__price__main {
                    font-size: 45px;
                    font-weight: 700;
                    color: var(--dark_color);
                }

                .imm__pcppcp__price__sub {
                    font-size: 15px;
                    color: var(--dark_color);
                }
            }

            .imm__pcppcp__description {
                font-size: 11px;
                color: #6C6965;
                line-height: normal;
            }

            .imm__pcppcp__bonus_text {
                font-size: 11px;
                font-weight: 700;
                color: var(--secondary_color);
                line-height: normal;
            }
        }

        .imm__packages_component__plans__plan_card__btn {
            padding: 13px 22px;
            border: 2px solid var(--primary_color);
            justify-content: space-between;
            border-radius: 16px;
            width: 100%;

            span {
                font-size: 18px;
                font-weight: 600;
                color: var(--primary_color);
            }

            img {
                max-width: 21px;
            }

            &:hover {
                background-color: #f6f9ff;
            }
        }

        .imm__packages_component__plans__plan_card__features__container {
            padding-top: 20px;
            min-height: 250px;

            .imm__pcppcf__head {
                padding-bottom: 20px;

                .imm__pcppcf__title {
                    font-size: 16px;
                    font-weight: 700;
                    color: #031F39;
                }

                .imm__pcppcf__description {
                    font-size: 13px;
                    color: #6C6965;
                }
            }

            .imm__pcppcf__features {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                padding-bottom: 20px;

                .imm__pcppcf__features__feature {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;

                    img {
                        max-width: 20px;
                    }

                    p {
                        font-size: 13px;
                        font-weight: 500;
                        color: var(--primary_color);
                        line-height: normal;
                    }
                }
            }

            .imm__packages_component__plans__plan_card__bonus_text {
                font-size: 15px;
                font-weight: 600;
                color: var(--secondary_color);
                text-align: center;
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {

    .imm__packages_component__plans__plan_card__features__container {
        min-height: fit-content !important;
    }

    .imm__packages_component__plans__plan_card__price {
        min-height: fit-content !important;
        padding-bottom: 20px;
    }

    .imm__packages_component__plans {
        scrollbar-width: none;

        .imm__packages_component__plans__plan_card {
            width: 100%;
        }

        .imm__packages_component__plans__plan_card__four_seasons {
            width: 100% !important;
            min-width: 100% !important;

            .imm__pcppcp__description {
                padding-bottom: 0;
            }

            .imm__packages_component__plans__plan_card__body {
                display: block;

                .imm__packages_component__plans__plan_card__body__column {
                    width: 100%;

                    .imm__packages_component__plans__plan_card__features__container {
                        padding-top: 20px !important;
                    }
                }
            }
        }
    }
}