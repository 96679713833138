.imm__live_help__recent_chats { 
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        width: 40%;
    }

    .imm__live_help__recent_chats__list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 16px;

        .imm__live_help__recent_chats__list__item {
            padding: 12px 24px;
            border-radius: 14px;
            border: 1px solid var(--primary_color);
            box-shadow: var(--primary_shadow_even);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            min-height: 54px;
            min-width: 345px;

            .imm__live_help__recent_chats__list__item_title {
                text-align: start;
                width: 100%;
            }

            .imm__live_help__recent_chats__list__item_metadata {
                display: flex;
                justify-content: space-between;
                width: 100%;

                span {
                    font-size: 14px;
                }

                @media (min-width: 768px) {
                    span {
                        font-size: 16px;
                    }
                }

            }
        }
    }

    .imm__live_help__recent_chats__action__buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 40px;

        .imm__live_help__recent_chats__action__buttons__live_expert, 
        .imm__live_help__recent_chats__action__buttons__ai_chat {
            padding: 12px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 35px;
            font-size: 16px;
            font-weight: 700;
            border: 1px solid var(--primary_color); 

            &.disabled {
                background-color: var(--disabled_color);
                color: var(--disabled_text_color);
                border: 1px solid var(--disabled_color);
                cursor: not-allowed;
            } 
            
        }

        .imm__live_help__recent_chats__action__buttons__ai_chat {
            background-color: var(--primary_color);
            color: var(--white_color);
        }
    }

}
