.imm__organization_invitation__modal_overlay {
    --imm__organization_invitation_modal__max_width: 530px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__organization_invitation__modal_overlay {
        --imm__organization_invitation_modal__max_width: 100%;
    }
}

.ReactModal__Content--after-open.imm__organization_invitation__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__organization_invitation__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__organization_invitation_modal__max_width);
}

.imm__organization_invitation__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;

    .imm__organization_invitation__inner_wrap {
        position: relative;
        background-color: var(--tertiary_color);
        border-radius: 7.5px;
        border: 1px solid #e3e3e2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 25px 15px;
        .imm__organization_invitation__list {
            margin-top: 20px;
            width: 100%;

            .imm__organization_invitation__item {
                border-bottom: 1px solid #e3e3e2;
                width: 100%;
                padding: 15px 0;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            .imm__organization_invitation__action {
                padding-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;

                button {
                    min-width: 170px;
                }
            }
        }

        .imm__organization_invitations__list {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 16px;
            max-height: 400px;
            overflow-y: auto;
            .imm__organization_invitation__list {
                .imm__organization_invitation__note {
                    font-size: 12px;
                }
                .imm__organization_invitation__item {
                    border-bottom: 0;
                    padding: 0;
                    padding-left: 16px;

                    .imm__organization_invitation__item_address {
                        font-size: 12px;
                    }
                }

                .imm__organization_invitation__action  {
                    width: 100%;
                    border-bottom: 1px solid #e3e3e2;
                    padding-bottom: 15px;
                }
            }
        }

        .close_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary_color);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;

            &:hover {
                color: var(--primary_hover_color);
            }

            svg {
                width: 35px;
                height: 35px;
                min-width: 35px;
                max-width: 35px;
                stroke-width: 0.5;
            }
        }

        .imm__organization_invitation__list {
            margin-top: 20px;
            width: 100%;

            .imm__organization_invitation__item {
                border-bottom: 1px solid #e3e3e2;
                width: 100%;
                padding: 15px 0;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            .imm__organization_invitation__action {
                padding-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;

                button {
                    min-width: 170px;
                }
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .ReactModal__Content--after-open.imm__organization_invitation__modal_dialog {
        top: 0;
    }

    .imm__organization_invitation__modal_overlay {
        background-color: var(--tertiary_color);
    }

    .imm__organization_invitation__modal_dialog {
        padding: 0;
    }

    .imm__organization_invitation__modal_content {
        padding: 15px;
        border-radius: 0;
        background-color: var(--tertiary_color);
        box-shadow: none;

        .imm__organization_invitation__inner_wrap {
            border-radius: none;
            border: none;
            padding: 25px 0 20px 0;
        }
    }
}