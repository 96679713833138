.ReactModal__Content--after-open.imm__packages__modal_dialog {
    top: 0px;
    transition-duration: .3s;
    padding: 0;
    width: 100%;
    height: 100%;
}

.imm__packages__modal_dialog {
    width: 100%;
    height: 100%;
}

.imm__packages__modal_content {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--white_color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    overflow: hidden;

    .close_btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;
        z-index: 1000;

        svg {
            width: 32px;
            height: 32px;
            stroke-width: 0.5;
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__packages__modal_content {
        padding: 30px 15px;

        .close_btn {
            padding: 10px;
        }
    }
}