.imm__live_help__page_view {
    padding: 30px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imm__live_help__page_view__loading {
        margin-top: 30px; 
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: var(--primary_color);
    }

    .imm__live_help__page_view__error {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: var(--error_color);
    }
}

@media (min-width: 768px) {
    .imm__live_help__page_view {
        padding: 30px 16px;
    }
}