.imm__book_consult2__page_view {
    background: var(--white_color);
    overflow-x: hidden;

    .imm__book_consult2__banner_section {
        width: 100%;
        background-color: #3D62AE;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        padding: 80px 0 80px 140px;

        .banner_title {
            width: 100%;
            font-size: 56px;
            font-weight: 500;
            color: var(--white_color);
            z-index: 1;
            position: inherit;
            max-width: 600px;
            text-align: start;
            line-height: 66px;

            .banner_title__text_exmphasis {
                background-color: #3DB54A;
                border-radius: 40px;
                padding: 0 16px;
            }
        }

        .banner_para {
            width: 100%;
            max-width: 552px;
            line-height: 34px;
            font-size: 24px;
            font-weight: 500;
            color: var(--white_color);

            .banner_para__text_emphasis {
                font-weight: 600;
            }
        }


        .banner_action__btns {
            display: flex;
            gap: 10px;
            margin-top: 20px;
        }

        button {
            z-index: 1;
            width: 100%;
            max-width: 270px;
            max-height: 60px;
            position: inherit;
            border: none;
            outline: none;
            transition: 0.3s all;
            font-size: 20px;
            font-weight: 600;
            padding: 15px 40px;
            border-radius: 40px;
            cursor: pointer;
        }

        .schedule_btn {
            border: 2px solid var(--white_color);
            color: var(--white_color);
            background-color: transparent;
            margin-right: 20px;
            // &:hover {
            //     background-color: var(--white_color);
            //     color: #3D62AE;
            //     border: none;
            //     outline: none;
            // }
        }

        .upgrade_btn {
            color: var(--primary_color);
            background-color: var(--white_color);

            &:hover {
                background-color: #f2f2f2;
                border: none;
                outline: none;
            }
        }

        img {
            position: absolute;
            right: 10px;
            bottom: 0;
            z-index: 0;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .imm__book_consult2__why_upgrade_section {
        padding: 80px 0;
        width: 100%;
        margin: 0 auto;
        background-color: #E6E8FF;

        .why_upgrade_title {
            text-align: center;
            font-size: 48px;
            font-weight: 600;
            color: var(--primary_color);
            margin-bottom: 60px;
        }

        .why_upgrade__cards {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 40px;
            flex-wrap: wrap;

            .why_upgrade__card {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                background-color: var(--white_color);
                padding: 24px;
                border-radius: 10px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                width: 430px;
                height: 430px;

                .card_header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .card_title {
                        color: var(--primary_color);
                        font-size: 24px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }

                    img {
                        height: 80px;
                        width: 80px;
                        object-fit: contain;
                    }
                }

                img {
                    height: 80px;
                    width: 80px;
                    object-fit: contain;
                }

                .card_title {
                    color: var(--primary_color);
                    font-size: 24px;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                .card_body {
                    h5 {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .imm__book_consult2__packages {
        padding: 80px;
        width: 100%;
        background-color: var(--white_color);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .imm__book_consult2__packages_title {
            font-size: 48px;
            font-weight: 600;
            color: var(--primary_color);
            text-align: center;
            margin-bottom: 20px;
        }

        .imm__book_consult2__packages_plan_tabs {
            display: flex;
            width: fit-content;
            justify-content: center;
            gap: 10px;
            background-color: #F4F7FD;
            border-radius: 20px;
            border: 1px solid #E6E8FF;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

            .imm__book_consult2__packages_plan_tab_btn {
                min-width: 200px;
                min-height: 48px;
                max-width: 200px;
                max-height: 48px;
                padding: 16px 20px;
                border-radius: 20px;
                font-size: 16px;
                font-weight: 600;
            }

            .imm__book_consult2__packages_plan_tab_btn__active {
                background-color: var(--primary_color);
                color: var(--white_color);
            }
        }

        .subtitle {
            color: #031F39;
            margin-bottom: 10px;
        }

        .imm__book_consult2__packages_plans {
            min-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            .imm__book_consult2__packages_plan_card {
                min-width: 325px;
                max-width: 325px;
                max-height: 630px;
                border-radius: 20px;
                border: 1px solid var(--primary_color);

                .imm__book_consult2__packages_plan_card_header {
                    border-radius: 20px 20px 0 0;
                    padding: 20px;
                    background-color: var(--primary_color);
                    display: flex;
                    justify-content: space-between;

                    .imm__book_consult2__packages_plan_card_title {
                        font-size: 24px;
                        font-weight: 600;
                        color: var(--white_color);
                    }

                    .imm__book_consult2__packages_plan_card_recommended_tag {
                        background-color: var(--white_color);
                        color: var(--primary_color);
                        padding: 1%;
                        border-radius: 10px;
                        font-size: 14px;
                        padding: 4px 8px;
                    }
                }

                .imm__book_consult2__packages_plan_card_body {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .price_section {
                        padding: 40px 20px 20px 20px;
                        min-height: fit-content;

                        .price {
                            font-size: 48px;
                            font-weight: 600;
                        }

                        .price_hidden {
                            font-size: 20px;
                            font-weight: 600;
                        }

                        .annual_price {
                            font-size: 12px;
                            font-weight: 400;
                            color: #6C6965;
                        }

                        .bonus_price_text {
                            color: var(--success_color);
                            font-size: 14px;
                            font-weight: 600;
                        }

                        .price_period {
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }

                    .get_started_button {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px 20px;
                        border: 2px solid var(--primary_color);
                        border-radius: 20px;

                        span {
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--primary_color);
                        }

                        img {
                            object-fit: contain;
                        }

                    }
                }

                .imm__book_consult2__packages_plan_card_footer {
                    padding: 24px 20px;
                    display: flex;
                    flex-direction: column;

                    .feature_title {}

                    .feature_subtitle {
                        font-size: 14px;
                        font-weight: 400;
                        color: #6C6965;
                        padding-bottom: 16px;
                    }

                    .features {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        color: var(--primary_color);
                        max-width: 300px;

                        li {
                            display: flex;
                            align-items: flex-start;
                            gap: 6px;
                            font-weight: 400;

                            .text_emphasis {
                                font-weight: 600;
                            }

                        }
                    }

                    .bonus_text {
                        text-align: center;
                        color: var(--success_color);
                        font-size: 15px;
                        font-weight: 500;
                        padding-top: 16px;
                    }

                }
            }

            .imm__book_consult2__packages_plan_card_recommended {
                border: 2px solid var(--success_color);

                .imm__book_consult2__packages_plan_card_header {
                    background-color: var(--success_color);
                }
            }

            .imm__book_consult2__packages_plan_card_disabled {
                background-color: #EBEBEB;
            }
        }

        .imm__book_consult2__packages_four_seasons {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-center;
            align-items: center;
            gap: 20px;
            border-radius: 20px;
            border: 1px solid var(--primary_color);

            .four_seasons_header {
                width: 100%;
                border-radius: 20px 20px 0 0;
                background-color: var(--primary_color);
                color: var(--white_color);
                padding: 10px 20px;

                .four_seasons_title {
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            .four_seasons__grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 120px;
                padding: 10px 20px 40px 20px;

                .grid_item_1 {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .package_overview {
                        font-size: 14px;
                    }

                    .get_quote_btn {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px 20px;
                        border: 2px solid var(--primary_color);
                        border-radius: 20px;

                        span {
                            font-size: 18px;
                            font-weight: 600;
                            color: var(--primary_color);
                        }

                        img {
                            object-fit: contain;
                        }
                    }
                }

                .grid_item_2 {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .feature_title {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .features {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        color: var(--primary_color);
                        width: 100%;

                        li {
                            display: flex;
                            align-items: flex-start;
                            font-size: 14px;
                            gap: 6px;
                            font-weight: 400;

                            .text_emphasis {
                                font-weight: 600;
                            }

                        }
                    }
                }

                .grid_item_3 {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .title {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .description {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .imm__book_consult2__meet_section {
        width: 100%;
        background-color: #ecf4ff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;

        .titles {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 40px;
            padding-left: 100px;
        }

        .meet_title {
            max-width: 620px;
            font-size: 48px;
            font-weight: 600;
            color: var(--primary_color);
            text-align: start;
            line-height: 66px;
        }

        .meet_para {
            font-size: 20px;
            font-weight: 500;
            color: var(--primary_color);
            text-align: start;
            line-height: 30px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    .imm__book_consult2__recog_section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 80px 0;
        width: 100%;

        button {
            z-index: 1;
            position: inherit;
            background-color: #39b54a;
            color: white;
            border: none;
            outline: none;
            transition: 0.3s all;
            font-size: 22px;
            font-weight: 600;
            padding: 15px 40px;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 30px;

            &:hover {
                background-color: #1e972e;
                border: none;
                outline: none;
            }
        }

        .recog_para {
            color: #8a8a8e;
            font-size: 20px;
            max-width: 650px;
            margin-bottom: 60px !important;
        }

        .title {
            color: var(--primary_color);
            font-size: 48px;
            font-weight: 600;
            max-width: 650px;
            margin-bottom: 50px;
        }

        .recog_items {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            width: 100%;

            .recog_item_ele {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                background-color: #ecf4ff;
                width: 300px;
                height: 320px;
                padding: 0 20px;
                border-radius: 10px;

                img {
                    height: 80px;
                    width: 80px;
                    object-fit: contain;
                }

                .item_title {
                    color: var(--primary_color);
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                p {
                    font-weight: 500;
                }
            }
        }
    }

    .imm__book_consult2__reviews {
        width: 100%;
        background-color: #E6E8FF;
        padding: 80px 0;

        .imm__book_consult2__reviews_title {
            font-size: 48px;
            font-weight: 600;
            color: var(--primary_color);
            text-align: center;
            margin-bottom: 80px;
        }

        .reviews {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 40px;

            .review_card {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                // align-items: center;
                gap: 20px;
                background-color: var(--white_color);
                padding: 40px 20px;
                border-radius: 20px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                width: 400px;
                min-height: 300px;
                max-height: 300px;

                .review_description {
                    font-size: 18px;
                    font-weight: 500;
                    color: var(--primary_color);
                }

                .review_author {
                    font-size: 16px;
                    font-weight: 500;
                    color: #6A6A7A;
                }
            }

        }
    }

    .imm__book_consult2__upgrade_prompt_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 80px 0;
        width: 100%;
        background-color: var(--primary_color);
        color: var(--white_color);

        .upgrade_prompt_title {
            font-size: 48px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 40px;
        }

        .upgrade_prompt_para {
            font-size: 24px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 40px;
        }

        .upgrade_prompt_btn {
            background-color: var(--white_color);
            color: var(--primary_color);
            border: none;
            outline: none;
            transition: 0.3s all;
            font-size: 22px;
            font-weight: 600;
            padding: 15px 40px;
            border-radius: 40px;
            cursor: pointer;
            margin: 0 auto;

            &:hover {
                background-color: #f2f2f2;
                border: none;
                outline: none;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .imm__book_consult2__banner_section {
        padding-left: 40px !important;

        .banner_title {
            font-size: 50px !important;
            line-height: 60px !important;
        }

        .banner_para {
            font-size: 20px !important;
            line-height: 30px !important;

            .banner_para__text_emphasis {
                font-weight: 600 !important;
            }
        }

        button {
            font-size: 16px !important;
            padding: 10px 20px !important;

        }
    }

    .imm__book_consult2__why_upgrade_section {
        padding-left: 20px !important;
        padding-right: 20px !important;

        .why_upgrade_title {
            font-size: 30px !important;
        }

        .why_upgrade__cards {
            .why_upgrade__card {
                width: 350px !important;
                height: 350px !important;

                .card_header {
                    .card_title {
                        font-size: 20px !important;
                    }

                    img {
                        height: 70px !important;
                        width: 70px !important;
                    }
                }

                img {
                    height: 70px !important;
                    width: 70px !important;
                }

                .card_title {
                    font-size: 20px !important;
                }

                .card_body {
                    h5 {
                        font-size: 16px !important;
                    }
                }
            }
        }
    }

    .imm__book_consult2__meet_section {
        .titles {
            padding-left: 70px !important;
            justify-content: center !important;
            width: 100%;
        }

        .meet_title {
            text-align: center !important;
            width: 100% !important;
            font-size: 30px !important;
            line-height: 40px !important;
        }

        .meet_para {
            font-size: 15px !important;
            line-height: 25px !important;
        }

        img {
            width: 400px !important;
        }
    }

    .imm__book_consult2__recog_section {
        button {
            font-size: 16px !important;
        }

        .recog_para {
            font-size: 16px !important;
            max-width: 650px !important;
        }

        .title {
            font-size: 23px !important;
        }

        .recog_items {
            .recog_item_ele {
                width: 220px !important;
                height: 300px !important;

                .item_title {
                    font-size: 16px !important;
                }

                p {
                    font-size: 14px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 843px) {
    .imm__book_consult2__banner_section {
        padding: 40px 15px !important;
        flex-direction: column !important;
        align-items: center !important;

        .banner_title {
            font-size: 30px !important;
            line-height: 40px !important;
            text-align: center !important;
            max-width: 100% !important;
        }

        .banner_para {
            font-size: 15px !important;
            line-height: 25px !important;
            text-align: center !important;
            max-width: 100% !important;

            .banner_para__text_emphasis {
                font-weight: 600 !important;
            }
        }

        img {
            display: none !important;
        }

        .banner_action__btns {
            flex-direction: column !important;
            gap: 20px !important;
            margin-top: 20px !important;
        }

        button {
            font-size: 12px !important;
            padding: 10px 20px !important;
            width: 100% !important;
            max-width: 400px !important;
        }

        img {
            position: initial !important;
        }
    }

    .imm__book_consult2__why_upgrade_section {
        padding: 40px 15px !important;

        .why_upgrade_title {
            font-size: 25px !important;
            margin-bottom: 30px !important;
        }

        .why_upgrade__cards {
            flex-direction: column !important;
            gap: 20px !important;

            .why_upgrade__card {
                width: 100% !important;
                height: 300px !important;

                .card_header {
                    .card_title {
                        font-size: 18px !important;
                    }

                    img {
                        height: 60px !important;
                        width: 60px !important;
                    }
                }

                img {
                    height: 60px !important;
                    width: 60px !important;
                }

                .card_title {
                    font-size: 18px !important;
                }

                .card_body {
                    h5 {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }

    .imm__book_consult2__meet_section {
        flex-direction: column !important;
        padding: 40px 15px !important;
        gap: 20px !important;
        width: 100%;

        .titles {
            width: 100% !important;
            display: flex !important;
            padding-left: 0 !important;
            justify-content: center !important;
        }

        .meet_title {
            width: 100%;
            font-size: 30px !important;
            line-height: 44px !important;
            text-align: center !important;
        }

        .meet_para {
            font-size: 14px !important;
            line-height: 15px !important;
            text-align: center !important;
            align-self: stretch !important;
        }

        img {
            width: 100% !important;
        }
    }

    .imm__book_consult2__packages {
        padding: 40px 15px !important;

        .imm__book_consult2__packages_title {
            font-size: 25px !important;
            margin-bottom: 20px !important;
        }

        .subtitle {
            font-size: 12px !important;
            margin-bottom: 10px !important;
        }

        .imm__book_consult2__packages_plan_tabs {
            .imm__book_consult2__packages_plan_tab_btn {
                min-width: 150px !important;
                min-height: 40px !important;
                max-width: 150px !important;
                max-height: 40px !important;
                padding: 10px 15px !important;
                font-size: 12px !important;
            }
        }

        .imm__book_consult2__packages_plans {
            .imm__book_consult2__packages_plan_card {
                width: 100% !important;
            }
        }

        .imm__book_consult2__packages_four_seasons {
            .four_seasons_header {
                .four_seasons_title {
                    font-size: 24px !important;
                }
            }

            .four_seasons__grid {
                grid-template-columns: 1fr !important;
                gap: 40px !important;

                .grid_item_1 {
                    .package_overview {
                        font-size: 12px !important;
                    }

                    .get_quote_btn {
                        padding: 10px 15px !important;

                        span {
                            font-size: 14px !important;
                        }
                    }
                }

                .grid_item_2 {
                    .feature_title {
                        font-size: 14px !important;
                    }

                    .features {
                        li {
                            font-size: 12px !important;
                        }
                    }
                }

                .grid_item_3 {
                    .title {
                        font-size: 14px !important;
                    }

                    .description {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }

    .imm__book_consult2__recog_section {
        padding: 40px 15px !important;

        button {
            font-size: 12px !important;
            padding: 10px 20px !important;
            width: 100% !important;
            margin-bottom: 20px !important;
            max-width: 300px !important;
        }

        .recog_para {
            font-size: 14px !important;
            max-width: 80% !important;
            margin-bottom: 40px !important;
        }

        .title {
            font-size: 18px !important;
            margin-bottom: 30px !important;
        }

        .recog_items {
            flex-direction: column !important;
            gap: 20px !important;

            .recog_item_ele {
                width: 100% !important;
                height: 250px !important;
                max-width: 300px !important;

                img {
                    width: 40px !important;
                    height: 40px !important;
                }

                .item_title {
                    font-size: 16px !important;
                }

                p {
                    font-size: 14px !important;
                    text-align: center !important;
                }
            }
        }
    }

    .imm__book_consult2__upgrade_prompt_section {
        padding: 40px 15px !important;

        .upgrade_prompt_title {
            font-size: 25px !important;
            margin-bottom: 20px !important;
        }

        .upgrade_prompt_para {
            font-size: 15px !important;
            margin-bottom: 20px !important;
        }

        .upgrade_prompt_btn {
            font-size: 12px !important;
            padding: 10px 20px !important;
            width: 100% !important;
            max-width: 300px !important;
        }
    }

    .imm__book_consult2__reviews {
        padding: 40px 15px !important;

        .imm__book_consult2__reviews_title {
            text-align: center !important;
            font-size: 25px !important;
            line-height: 25px;
            margin-bottom: 40px !important;
        }

        .reviews {
            .review_card {
                width: 100% !important;
                min-height: 250px !important;

                .review_description {
                    font-size: 14px !important;
                }

                .review_author {
                    font-size: 12px !important;
                }
            }
        }
    }
}