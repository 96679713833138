.imm__live_help__welcome__content {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 36px;
    background-color: var(--white_color);
    box-shadow: var(--primary_shadow_even);
    border-radius: 15px;
    gap: 26px;

    .imm__live_help__welcome__content__body {
        color: var(--primary_color);
        font-size: 16px;
        text-align: center;
    }

    .imm__live_help__welcome__content__buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .imm__live_help__welcome__content__buttons__live_expert, 
        .imm__live_help__welcome__content__buttons__ai_chat {
            padding: 12px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 35px;
            font-size: 16px;
            font-weight: 700;
            border: 1px solid var(--primary_color); 

            &.disabled {
                background-color: var(--disabled_color);
                color: var(--disabled_text_color);
                border: 1px solid var(--disabled_color);
                cursor: not-allowed;
            }
        }

        .imm__live_help__welcome__content__buttons__ai_chat {
            background-color: var(--primary_color);
            color: var(--white_color);
        }


    }

};

@media  (min-width: 768px) {
    .imm__live_help__welcome__content {
        width: 40%;
        .imm__live_help__welcome__content__body {
            font-size: 20px;
        }
    }
}