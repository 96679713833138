.imm__auth_input_container__error {
    .imm__input_error__cont {
        display: flex !important;
    }
}

.imm__auth_input_container {
    margin-top: 20px;
    width: 100%;

    .label__cont {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
            font-size: 14px;
            font-weight: 600;
            text-align: left;
        }
    }

    .input_group {
        position: relative;
        width: 100%;

        input {
            background: #f3f3f3;
            padding: 15px;
            border-radius: 8px;
        }

        .password_toggle_btn {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 25px;
                height: 25px;
            }

            .visible_pass_eye {
                color: #3f62ad;
            }

            .hidden_pass_eye {
                color: #bbbbbb;
            }

        }
    }

    .imm__input_error__cont {
        width: 100%;
        display: none;
        justify-content: flex-end;
        align-items: center;

        .input_error {
            text-align: end;
            color: #b31616;
            font-size: 10px;
            font-weight: 600;
        }
    }
}

.imm__input_container__primary__error {
    .input_error__cont {
        display: flex !important;
    }
}

.imm__input_container__primary {
    .label__cont {
        padding-bottom: 7px;

        label {
            font-weight: 600;
            text-align: left;
        }
    }

    .input_group {
        width: 100%;

        input,
        textarea {
            background: var(--white_color);
            padding: 15px;
            border-radius: 8px;
        }
    }

    .input_error__cont {
        width: 100%;
        display: none;
        justify-content: flex-end;
        align-items: center;

        .input_error {
            text-align: end;
            color: var(--error_color);
            font-size: 14px;
            font-weight: 600;
        }
    }
}