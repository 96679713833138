.fit_asset_max {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.hide__opa_vis {
    opacity: 0 !important;
    visibility: hidden !important;
}

.text_center {
    text-align: center;
}

.text_left {
    text-align: left;
}

.text_right {
    text-align: right;
}

.text_underline { 
    text-decoration: underline;
}

.text_italic {
    font-style: italic;
}

.color_primary {
    color: var(--primary_color) !important;
}

.color_secondary {
    color: var(--secondary_color) !important;
}

.color_disabled {
    color: var(--disabled_color) !important;
}

.color_grey {
    color: var(--grey_color) !important;
}

.color_success {
    color: var(--success_color) !important;
}

.color_error {
    color: var(--error_color) !important;
}

.color_warning {
    color: var(--warning_color) !important;
}

.color_info {
    color: var(--info_color) !important;
}

.color_dark {
    color: var(--dark_color) !important;
}

ul[type^="bullets"] {
    li {
        list-style: disc;
    }
}

ul[type^="numbers"] {
    li {
        list-style: decimal;
    }
}

ul[type^="lower_alpha"] {
    li {
        list-style: lower-alpha;
    }
}

.d_none {
    display: none !important;
}

@media #{map-get($media_queries, mobile)} {
    .d_mob_none {
        display: none !important;
    }
}

@media #{map-get($media_queries, desktop)} {
    .d_web_none {
        display: none !important;
    }
}