.imm__visa_chances {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 20px 0;

    .imm__visa_chances__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        min-height: 40vh;

        p {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: var(--primary_color);
        }
    }

    .imm__visa_chances__error {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        min-height: 40vh;

        p {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: var(--error_color);
        }
    }

    .imm__visa_chances__indicator {
        position: relative;

        img {
            height: 112px;
            width: 224px;
        }

        .imm__visa_chances__indicator__text {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -50%);

            .imm__visa_chances__indicator__text_heading {
                font-size: 14px;
            }

            .imm__visa_chances__indicator__text_subheading {
                font-size: 24px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }

    .imm__visa_chances__switch {
        margin-top: 36px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        background-color: #D9E1F2;
        height: 50px;
        width: fit-content;
        padding: 3px;

        .imm__visa_chances__switch__item {
            padding: 16px;
            width: 101px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;

            p {
                font-weight: 500;
                font-size: 14px;
            }
        }

        .imm__visa_chances__switch__item_selected {
            box-shadow: var(--primary_shadow_even);
            background-color: var(--white_color);
        }
    }

    .imm__visa_chances__chances_list {
        margin-top: 26px;
        width: 100%;
        height: 100%;

        .imm__visa_chances__chances_list__empty_text {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 16px;
            color: var(--secondary_dark_color);
        }

        .imm__visa_chances__chances__accordion {
            border-bottom: 1px solid #D9E1F2;
            padding: 10px 25px;

            &:last-child {
                border-bottom: none;
            }

            .imm__visa_chances__chances__accordion__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                cursor: pointer;

                p {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: start;

                    .imm__visa_chances__chances__accordion__header__icon {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        min-height: 20px;
                    }

                    .imm__visa_chances__chances__accordion__header__icon__good {
                        color: var(--secondary_color);
                    }

                    .imm__visa_chances__chances__accordion__header__icon__bad {
                        color: var(--heart_red_color);
                    }

                    .imm__visa_chances__chances__accordion__header__icon__neutral {
                        color: var(--secondary_dark_color);
                    }
                }

                .imm__visa_chances__chances__accordion__header__icon__arrow {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    color: var(--primary_color);
                }
            }

            .imm__visa_chances__chances__accordion__content {
                display: none;
            }

            .imm__visa_chances__chances_accordion__content_active {
                display: block;
                padding: 2px 20px 0 30px;

                p {
                    font-size: 12px;
                }
            }
        }
    }

    .imm__visa_chances__book_consultation {
        margin-top: 36px;
        width: 382px;
        height: 151px;
        background-color: var(--white_color);
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        box-shadow: var(--primary_shadow_even);
        border-radius: 15px;

        p {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary_color);
            color: var(--white_color);
            box-shadow: var(--primary_shadow_even);
            border-radius: 35px;
            width: 288px;
            height: 48px;
            font-size: 14px;
            font-weight: 600;
        }
    }

    @media (min-width: 768px) {
        padding: 40px;

        .imm__visa_chances__loading,
        .imm__visa_chances__error {
            p {
                font-size: 24px;
            }
        }

        .imm__visa_chances__indicator {
            img {
                height: 150px;
                width: 300px;
            }

            .imm__visa_chances__indicator__text {
                top: 72%;

                .imm__visa_chances__indicator__text_heading {
                    font-size: 16px;
                }

                .imm__visa_chances__indicator__text_subheading {
                    font-size: 32px;
                }
            }
        }

        .imm__visa_chances__switch {
            width: 300px;
            height: 56px;

            .imm__visa_chances__switch__item {
                width: 200px;
                height: 50px;

                p {
                    font-size: 16px;
                }
            }
        }

        .imm__visa_chances__chances_list {
            max-height: none;
            flex: 1;
            overflow-y: auto;
            width: 50%;

            .imm__visa_chances__chances__accordion {
                padding: 15px 25px;

                .imm__visa_chances__chances__accordion__header {

                    p {
                        font-size: 20px;

                        .imm__visa_chances__chances__accordion__header__icon {
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            min-height: 24px;
                        }
                    }

                    .imm__visa_chances__chances__accordion__header__icon__arrow {
                        width: 30px;
                        height: 30px;
                    }

                }

                .imm__visa_chances__chances_accordion__content_active {
                    padding: 10px 26px 0 35px;

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

        .imm__visa_chances__book_consultation {
            width: 50%;
            height: 100%;
            max-height: 151px;
            padding: 20px 40px;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;

            p {
                font-size: 16px;
            }

            button {
                width: 300px;
                height: 60px;
                font-size: 16px;
            }
        }
    }
}