.imm__expiry_notification__modal_overlay {
    --imm__expiry_notification_modal__max_width: 500px;
}

.ReactModal__Content--after-open.imm__expiry_notification__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__modal__expiry_notification {
    .expiry_notification__title {
        color: var(--dark_color);
        padding-bottom: 4px;
        font-weight: 500;

        svg {
            min-width: 20px;
            min-height: 20px;
            max-width: 20px;
            max-height: 20px;
            
            color: var(--primary_color);
        }
    }

    .expiry_notification__subtitle {
        color: var(--dark_grey_color);
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 400;
    }

    .expiry_notification__content {
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        gap:6px;

        .expiry_notification__content_items {
            .expiry_notification__content_title {
                color: var(--dark_color);
                padding-bottom: 2px;
                font-weight: 500;
                font-size: 16px;

                svg {
                    min-width: 18px;
                    min-height: 18px;
                    max-width: 18px;
                    max-height: 18px;
                    stroke-width: 2;
                    color: var(--primary_color);
                }
            }
            .expiry_notification__content_sec {
                color: var(--dark_grey_color);
                padding-left: 4px;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.imm__expiry_notification__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__expiry_notification_modal__max_width);
}

.imm__expiry_notification__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    position: relative;

    .imm__modal_close_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--dark_color);
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;

        &:hover {
            color: var(--primary_color);
        }

        svg {
            width: 24px;
            height: 24px;
            min-width: 24px;
            max-width: 24px;
            stroke-width: 0.5;
        }
    }
}

.imm__expiry_notification__modal_body {
    z-index: 1;
    padding: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    svg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        max-width: 30px;
    }

    .imm__expiry_notification__modal_right_content {
        h4 {
            padding-bottom: 5px;
            padding-right: 25px;
        }
    }
}

.imm__expiry_notification__modal_footer__center {
    justify-content: center !important;
}

.imm__expiry_notification__modal_footer {
    border-top: 1px solid #00000018;
    padding: 15px 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    overflow: hidden;

    .imm__expiry_notification__modal_btn {
        border: 1px solid transparent;
        padding: 7px 20px;
        border-radius: 50px;
        font-weight: 600;
    }

    .imm__expiry_notification__modal_bottom_close_btn {
        border-color: var(--primary_color);
        background-color: var(--tertiary_color);
        color: var(--primary_color);

        &:hover {
            background-color: var(--tertiary_hover_color);
        }
    }

    .imm__expiry_notification__modal_btn__info {
        border-color: var(--primary_color);
        background-color: var(--tertiary_color);

        &:hover {
            background-color: var(--tertiary_hover_color);
        }
    }

    .imm__expiry_notification__modal_btn__primary {
        border-color: var(--primary_color);
        background-color: var(--primary_color);
        color: var(--white_color);

        &:hover {
            background-color: var(--primary_hover_color);
        }
    }
}

@media only screen and (max-width: 500px) {
    .imm__expiry_notification__modal_overlay {
        --imm__expiry_notification_modal__max_width: 100%;
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__expiry_notification__modal_content {
        padding: 10px;

        .imm__modal_close_btn {
            svg {
                width: 20px;
                height: 20px;
                min-width: 20px;
                max-width: 20px;
            }
        }
    }

    .imm__expiry_notification__modal_body {
        padding: 10px 0;
        gap: 10px;

        svg {
            width: 20px;
            height: 20px;
            min-width: 20px;
            max-width: 20px;
        }

        .imm__expiry_notification__modal_right_content {
            .imm__expiry_notification__modal_content_sec {
                font-size: 14px;
            }
        }
    }
    .imm__expiry_notification__modal_footer {
        padding: 10px 0 0 0;

        .imm__expiry_notification__modal_btn {
            padding: 4px 20px;
            border-radius: 50px;
            font-weight: 500;
            font-size: 12px;
            min-width: 40px;
            color: var(--white_color);
        }
    }
}