.btn_block {
    width: 100% !important;
}

.btn_fit_content {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.imm__btn__primary {
    background-color: var(--primary_color);
    color: var(--white_color);

    &:hover {
        color: var(--white_color);
        background-color: var(--primary_hover_color);
    }

    .imm__btn_loader {
        border-color: var(--white_color);
    }
}

.imm__btn__primary__in_progress,
.imm__btn__primary__in_progress:disabled {
    background-color: var(--primary_color);
    color: var(--white_color);
    opacity: 0.7;
    cursor: initial;

    &:hover {
        background-color: var(--primary_color);
        color: var(--white_color);
    }
}

.imm__btn__secondary {
    background-color: var(--tertiary_color);
    color: var(--primary_color);

    &:hover {
        color: var(--primary_hover_color);
        background-color: var(--tertiary_hover_color);
    }
}

.imm__btn__secondary__in_progress,
.imm__btn__secondary__in_progress:disabled {
    background-color: var(--tertiary_color);
    color: var(--primary_color);
    opacity: 0.7;
    cursor: initial;

    &:hover {
        background-color: var(--tertiary_color);
        color: var(--primary_color);
    }
}

.imm__btn__tertiary {
    background-color: var(--white_color);
    color: var(--dark_color);
    border: solid 1px var(--primary_color);
    box-shadow: none !important;

    &:hover {
        color: var(--primary_hover_color);
        background-color: var(--tertiary_hover_color);
    }
}

.imm__btn__tertiary__in_progress,
.imm__btn__tertiary__in_progress:disabled {
    background-color: var(--tertiary_color);
    color: var(--primary_color);
    opacity: 0.7;
    cursor: initial;

    &:hover {
        background-color: var(--tertiary_color);
        color: var(--primary_color);
    }
}

.imm__btn__primary__disabled,
.imm__btn__secondary__disabled,
.imm__btn__tertiary__disabled {
    background-color: var(--disabled_color);
    color: var(--dark_color);
    cursor: default !important;
    border-color: transparent;

    &:hover {
        background-color: var(--disabled_color);
        color: var(--dark_color);
        border-color: transparent;
    }
}

.imm__regular__btn {
    width: fit-content;
    height: 40px;
    border-radius: 40px;
    padding: 0 70px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.438px;
    box-shadow: var(--primary_shadow_offset_bottom);

    svg {
        stroke-width: 1;
    }
}

.imm__icon__btn {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    padding: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.438px;
    box-shadow: var(--primary_shadow_offset_bottom);

    svg {
        width: 16px;
        height: 16px;
        min-height: 16px;
        min-width: 16px;
        stroke-width: 0.5;
    }
}