.imm__draws__page_view {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__draws_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .imm__draws_list_section {

        .imm__draws_loading,
        .imm__draws_no_draws_message {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            margin: 50px 0;
        }

        .imm__draws_no_draws_message {
            h4 {
                color: #808080
            }
        }
    }
}