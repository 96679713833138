.imm__program_filters__modal_overlay {
    --imm__program_filters_modal__max_width: 400px;
    --imm__program_filters_modal__max_height: 600px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__program_filters__modal_overlay {
        --imm__program_filters_modal__max_width: 100%;
        --imm__program_filters_modal__max_height: 600px;
    }
}

.ReactModal__Content--after-open.imm__program_filters__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__program_filters__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__program_filters_modal__max_width);
    max-height: var(--imm__program_filters_modal__max_height);
}

.imm__program_filters__modal_content {
    width: 100%;
    height: 100%;
    background-color: var(--white_color);
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    padding: 24px;

    .imm__program_filters_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .close_btn {
            padding: 1%;
            height: 30px;
            width: 30px;
            border-radius: 100%;
            background-color: var(--primary_color);
            svg {
                width: 20px;
                height: 20px;
                stroke-width: 0.5;
                color: var(--white_color);
            }
        }

        .imm__program_filters_header_title {
            font-weight: 600;
        }

        .imm__program_filters_clear_filter_btn {
            font-size: 14px;
            font-weight: 600;
            color: var(--primary_color);
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .imm__program_filters_body {
        display: flex;
        flex-direction: column;
        gap: 24px;
        
        .imm__program_filters_form {
            display: flex;
            flex-direction: column;
            gap:14px;

            .imm__program_filters_form_content {
                display: flex;
                flex-direction: column;
                gap: 14px;
                height: 100%;
                min-height: 100px;
                max-height: 400px;
                overflow-y: auto;
             
                .imm__program_filters_form_group {
                    display: flex;
                    gap: 16px;
                    .imm__program_filters_custom_checkbox {
                        position: relative; 
                        width: 24px;
                        height: 24px;
                        border: 1px solid #CFCFCF;
                        border-radius: 4px;
                        .imm__program_filters_checkbox {
                            position: absolute;
                            left: 0;
                            top:0;
                            opacity: 0;
                            width: 24px;
                            height: 24px;
                            cursor: pointer;
                            border-radius: 4px;
                            // opacity: 0;
                        }

                        .imm__program_filters_checkmark {
                            position: absolute;
                            top: -2px;
                            left: 2px;
                            svg {
                                width: 20px;
                                height: 20px;
                                color: var(--primary_color);
                            }
                        }

                        .imm__program_filters_checkbox:checked ~ .imm__program_filters_checkmark {
                            display: block;
                        }

                        .imm__program_filters_checkbox:checked ~ .imm__program_filters_checkmark:after {
                            display: block;
                        }
                        
                    }
    
                    .imm__program_filters_label {
                        font-size: 14px;
                        font-weight: 400;
                        cursor: pointer;
                    }
                }
            }
            .imm__program_filters_action_btn {
                background-color: var(--primary_color);
                padding: 12px;
                border-radius: 40px;
                font-size: 16px;
                font-weight: 600;
                color: var(--white_color);
                margin: 12px 0;
            }
        }
    }

}