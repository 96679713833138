.imm__payment__modal_overlay {
    --imm__payment_modal__max_width: 530px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__payment__modal_overlay {
        --imm__payment_modal__max_width: 100%;
    }
}

.ReactModal__Content--after-open.imm__payment__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__payment__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__payment_modal__max_width);
}

.imm__payment__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;

    .imm__payment_inner_wrap {
        position: relative;
        background-color: var(--tertiary_color);
        border-radius: 7.5px;
        border: 1px solid #e3e3e2;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 25px 15px;

        .close_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary_color);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;

            &:hover {
                color: var(--primary_hover_color);
            }

            svg {
                width: 35px;
                height: 35px;
                min-width: 35px;
                max-width: 35px;
                stroke-width: 0.5;
            }
        }

        .imm__payment_promo_code_container {
            .imm__payment_promo_code {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;

                input {
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 10px;
                    box-shadow: var(--primary_shadow_even);
                    padding: 7px 10px;
                }

                .imm__payment_apply_promo_code_btn {
                    padding: 7px 30px;
                    height: fit-content;
                    border-radius: 10px;
                    min-width: 110px;

                    &:disabled {
                        background-color: var(--disabled_color);
                        color: var(--grey_color);
                    }
                }

                .imm__payment_remove_promo_code_btn {
                    background-color: #bd3f3f;

                    &:hover {
                        background-color: #8b2e2e;
                    }
                }
            }

            .imm__payment_promo_code_responses {
                p {
                    font-weight: 500;
                    padding-top: 5px;
                    padding-left: 3px;

                    .color_disabled {
                        font-weight: 400;
                    }
                }
            }
        }

        .imm__payment_package_info {
            border-bottom: 1px solid #e3e3e2;
            padding-bottom: 15px;
            margin-bottom: 15px;

            .imm__payment_package_info_inner {
                .imm__payment_package_info_item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .original_data {
                        text-decoration: line-through;
                        font-size: 13px;
                        color: var(--grey_color);
                    }
                }
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .ReactModal__Content--after-open.imm__payment__modal_dialog {
        top: 0;
    }

    .imm__payment__modal_overlay {
        background-color: var(--tertiary_color);
    }

    .imm__payment__modal_dialog {
        padding: 0;
    }

    .imm__payment__modal_content {
        padding: 15px;
        border-radius: 0;
        background-color: var(--tertiary_color);
        box-shadow: none;

        .imm__payment_inner_wrap {
            border-radius: none;
            border: none;
            padding: 25px 0 20px 0;
        }
    }
}