.imm__careers__dashboard {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__careers__dashboard__navigation_cards {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 20px;
        }

        .imm__careers__dashboard__navigation_card__disabled {
            cursor: default !important;
            background-color: var(--light_grey_color) !important;
            border-color: var(--disabled_color) !important;

            &:hover {
                background-color: var(--light_grey_color) !important;
            }

            .imm__careers__dashboard__navigation_card__icon {
                background-color: var(--disabled_color) !important;

                svg {
                    color: var(--grey_color) !important;
                }
            }
        }

        .imm__careers__dashboard__navigation_card {
            cursor: pointer;
            background-color: var(--white_color);
            border-radius: 14px;
            border: 1px solid var(--medium_color);
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            height: 117px;
            width: 424px;
            padding: 20px 20px;
            gap: 15px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: var(--primary_color_lightest);
            }

            .imm__careers__dashboard__navigation_card__icon {
                min-width: 75px;
                min-height: 75px;
                max-width: 75px;
                max-height: 75px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--primary_color);
                border-radius: 50%;

                svg {
                    width: 42px;
                    height: 42px;
                    color: var(--white_color);
                }
            }

            .imm__careers__dashboard__navigation_card__content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 0px;

                .imm__careers__dashboard__navigation_card__content__title {
                    margin: 0px;
                }

                .imm__careers__dashboard__navigation_card__content__subtitle {
                    margin: 0px;
                    font-size: 14px;
                }
            }

            @media (max-width: 768px) {
                width: 100%;

                .imm__careers__dashboard__navigation_card__icon {
                    min-width: 64px;
                    min-height: 64px;
                    max-width: 64px;
                    max-height: 64px;

                    svg {
                        width: 36px;
                        height: 36px;
                    }
                }

                .imm__careers__dashboard__navigation_card__content {
                    .imm__careers__dashboard__navigation_card__content__subtitle {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}