.imm__packages_old__modal_overlay {
    --imm__packages_old_modal__max_width: 530px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__packages_old__modal_overlay {
        --imm__packages_old_modal__max_width: 100%;
    }
}

.ReactModal__Content--after-open.imm__packages_old__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__packages_old__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__packages_old_modal__max_width);
}

.imm__packages_old__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;

    .imm__packages_old__inner_wrap {
        position: relative;
        background-color: var(--tertiary_color);
        border-radius: 7.5px;
        border: 1px solid #e3e3e2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 25px 15px;

        .close_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary_color);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;

            &:hover {
                color: var(--primary_hover_color);
            }

            svg {
                width: 35px;
                height: 35px;
                min-width: 35px;
                max-width: 35px;
                stroke-width: 0.5;
            }
        }

        .imm__subscription_feature_list {
            border: 1px solid #D8D8D8;
            border-radius: 10px;
            width: 100%;

            .imm__sfl_container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 10px;
                border-bottom: 1px solid #D8D8D8;
                user-select: none;

                &:last-child {
                    border-bottom: none;
                }

                .imm__sfl_left {
                    flex: 2;

                    .imm__sfl_left__top {
                        cursor: pointer;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;

                        svg {
                            width: 10px;
                            height: 10px;
                            min-width: 10px;
                            max-width: 10px;
                            color: var(--disabled_color);
                        }

                        span {
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }

                    .imm__sfl_left__bottom {
                        font-size: 14px;
                        line-height: normal;
                        padding-left: 20px;
                        padding-top: 5px;
                    }
                }

                .imm__sfl_right {
                    flex: 0.7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    font-size: 14px;
                    padding-top: 2px;

                    svg {
                        min-width: 20px;
                        min-height: 20px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .imm__packages_old__list {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding-top: 30px;

            .imm__package_item__selected {
                outline-color: var(--primary_color) !important;
            }

            .imm__package_item__disabled {
                cursor: not-allowed !important;

                .discount_cont {
                    background: #C1C1C1 !important;
                }
            }

            .imm__package_item {
                border-radius: 26px;
                border: 1px solid #C1C1C1;
                outline: 3px solid transparent;
                background: var(--white_color);
                cursor: pointer;
                padding: 15px;
                position: relative;
                width: 182px;
                height: 138px;

                .discount_cont {
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: var(--primary_color);
                    color: var(--white_color);
                    padding: 5px 20px;
                    border-radius: 50px;
                    font-size: 15px;
                    font-weight: 600;
                }

                .package_title {
                    font-weight: 700;
                }

                .package_amount {
                    font-weight: 600;
                    color: #979797;
                }

                .original_price {
                    font-size: 14px;
                    color: #979797;
                    text-decoration: line-through;
                }

                .package_mo_descr {
                    padding-top: 10px;
                    color: #979797;
                    font-size: 12px;
                    line-height: normal;
                }

                .package_yr_descr {
                    padding-top: 10px;
                    font-weight: 600;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .ReactModal__Content--after-open.imm__packages_old__modal_dialog {
        top: 0;
    }

    .imm__packages_old__modal_overlay {
        background-color: var(--tertiary_color);
    }

    .imm__packages_old__modal_dialog {
        padding: 0;
    }

    .imm__packages_old__modal_content {
        padding: 15px;
        border-radius: 0;
        background-color: var(--tertiary_color);
        box-shadow: none;

        .imm__packages_old__inner_wrap {
            border-radius: none;
            border: none;
            padding: 25px 0 20px 0;
        }

        .imm__sfl_left {
            .imm__sfl_left__top {
                gap: 5px !important;

                svg {
                    width: 8px !important;
                    height: 8px !important;
                    min-width: 8px !important;
                    max-width: 8px !important;
                }

                span {
                    font-size: 12px !important;
                }
            }

            .imm__sfl_left__bottom {
                font-size: 12px !important;
                padding-left: 15px !important;
                padding-top: 3px !important;
            }
        }

        .imm__sfl_right {
            font-size: 12px !important;

            svg {
                min-width: 16px !important;
                min-height: 16px !important;
                width: 16px !important;
                height: 16px !important;
            }
        }

        .imm__packages_old__list {
            gap: 15px !important;

            .imm__package_item {
                width: 100% !important;
                height: 125px !important;
                border-radius: 22px !important;
                outline-width: 2px !important;
                padding: 15px 10px 10px 10px !important;

                .discount_cont {
                    top: -17px !important;
                    padding: 3px 15px !important;
                    font-size: 16px !important;
                }

                .package_title {
                    font-size: 15px;
                }

                .package_amount {
                    font-size: 15px;
                }

                .original_price {
                    font-size: 13px !important;
                }

                .package_mo_descr {
                    font-size: 11px !important;
                }

                .package_yr_descr {
                    padding-top: 5px !important;

                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}