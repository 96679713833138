.imm__results__temporary{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px -15px 0 -15px;
    
    .imm__results__temporary__prompt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        border-radius: 15px;
        gap: 20px;
        box-shadow: var(--primary_shadow_even);

        .imm__results__temporary__prompt__card_content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .imm__results__temporary__prompt__card_title {
                font-weight: 600;
                color: var(--primary_color);
            }

            .imm__results__temporary__prompt__card_subtitle {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .imm__results__temporary__prompt__card_image {
            display: flex;
            justify-content: center;
            align-items: center;

            max-width: 100px;
            max-height: 100px;
        }

    }

    .imm__results__temporary__visa_chances__card {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        border-radius: 15px;
        border: 1px solid var(--error_color);
        box-shadow: var(--primary_shadow_even);

        .imm__results__temporary__visa_chances__card__header__recommended {
            display: none;
        }

        .imm__results__temporary__visa_chances__card__content {
            display: flex;
            flex-direction: column;
            gap:4px;

            .imm__results__temporary__visa_chances__card__content_title {
                font-weight: 500;
            }
            
            .imm__results__temporary__visa_chances__card__content_subtitle {
                font-size: 12px;
                font-style: italic;
            }
        }

        .imm__results__temporary__visa_chances__card__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            .imm__results__temporary__visa_chances__card__btn {
                border-radius: 100%;
                background-color: var(--primary_color);
                min-width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
    
                svg {
                    color: white;
                    height: 16px;
                    width: 16px; 
                }
            }
        }
    };

    .imm__results__temporary__visa_chances__card__recommended {
        margin-top: 10px;
        border: 2px solid var(--primary_color);
        .imm__results__temporary__visa_chances__card__header__recommended {
            position: absolute;
            top: -20px;
            left: 20px;
            display: block;
            background-color: var(--primary_color);
            border-radius: 30px;
            padding: 2px 10px;

            .imm__results__temporary__visa_chances__card__header__recommended_title {
                font-size: 18px;
                color: white;
                font-weight: 600;
            }
        }

        .imm__results__temporary__visa_chances__card__content {
            margin-top: 10px;
        }
    }
    
    .imm__results__temporary__visa_chances__card__finished {
        border-color: var(--secondary_color);
    }

    // for desktop
    @media only screen and (min-width: 768px) {
        .imm__results__temporary__prompt {
            padding: 16px 32px;

            .imm__results__temporary__prompt__card_content {
                .imm__results__temporary__prompt__card_title {
                    font-size: 24px;
                }

                .imm__results__temporary__prompt__card_subtitle {
                    font-size: 18px;
                }
            }

            .imm__results__temporary__prompt__card_image {
                max-width: 150px;
                max-height: 150px;
            }
        }

        .imm__results__temporary__visa_chances__card {
            padding: 16px 32px;

            .imm__results__temporary__visa_chances__card__content {
                .imm__results__temporary__visa_chances__card__content_title {
                    font-size: 18px;
                }
                
                .imm__results__temporary__visa_chances__card__content_subtitle {
                    font-size: 14px;
                }
            }

            .imm__results__temporary__visa_chances__card__footer {
                .imm__results__temporary__visa_chances__card__btn {
                    min-width: 40px;
                    height: 40px;

                    svg {
                        height: 20px;
                        width: 20px; 
                    }
                }
            }
        }

        .imm__results__temporary__visa_chances__card__recommended {
            .imm__results__temporary__visa_chances__card__header__recommended {
                .imm__results__temporary__visa_chances__card__header__recommended_title {
                    font-size: 24px;
                }
            }
        }       
    }
}