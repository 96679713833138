.imm__main_layout {
    .imm__main_layout__content_box {}
}

@media #{map-get($media_queries, desktop)} {
    .imm_quest__main_section__style_001 {
        height: calc(100vh - 66px) !important;
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm_quest__main_section__style_001 {
        height: calc(100vh - 55px) !important;
    }
}

.imm_quest__questionnaire {
    background-color: transparent !important;
}