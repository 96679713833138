:root {
    --primary_color: #3D62AE;
    --primary_hover_color: #21407e;
    --primary_shadow_color: #2c4f9a2c;
    --primary_color_light: #F4F7FE;
    --primary_color_lightest: #E8EDF9;
    --secondary_color: #39B54A;
    --secondary_hover_color: #1f992f;
    --secondary_shadow_color: #2c9a4f2c;
    --tertiary_color: #F4F7FE;
    --tertiary_hover_color: #ecf1fd;
    --medium_color: #AEC3ED;
    --dark_color: #2C2C2C;
    --secondary_dark_color: #757575;
    --grey_color: #808080;
    --dark_grey_color: #4F4F4F;
    --light_grey_color: #E8E8E8;
    --white_color: #FFFFFF;
    --disabled_color: #BDBDBD;
    --success_color: #43A047;
    --error_color: #AA3C3C;
    --warning_color: #FFB300;
    --yellow_color: #F5B411;
    --black_color: #000000;
    --info_color: #1976D2;
    --heart_red_color: #EE3232;
    --heart_red_hover_color: #f04646;
}