.imm__questionnaire_disclaimer__page_view {
    max-width: 670px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__questionnaire_disclaimer_content_section {
        background-color: var(--white_color);
        box-shadow: var(--primary_shadow_even);
        border-radius: 14px;
        padding: 24px;

        ol {
            li {
                list-style-type: decimal;
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }

                a {
                    display: inline;
                }
            }
        }

        p {
            a {
                display: inline;
            }
        }

        .imm__questionnaire_disclaimer_checkbox_section {
            padding-top: 20px;

            .imm__qdcs__checkbox {
                width: fit-content;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: start;
                gap: 15px;
                cursor: pointer;

                &:first-child {
                    padding-bottom: 10px;
                }

                input {
                    display: none;
                }

                .imm__qdcs__checkbox_checker {
                    width: 24px;
                    height: 24px;
                    min-height: 24px;
                    min-width: 24px;
                    border-radius: 7px;
                    border: 2px solid var(--medium_color);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        height: 13px;
                        width: 13px;
                        min-height: 13px;
                        min-width: 13px;
                        stroke-width: 0.5;
                        color: var(--dark_color);
                    }
                }

                .imm__qdcs__checkbox_checker__selected {
                    box-shadow: var(--primary_shadow_even);
                    background-color: var(--medium_color);
                }

                label {
                    width: 100%;
                    text-align: left;
                    cursor: pointer;

                    a {
                        display: inline;
                    }
                }
            }
        }
    }

    .imm__questionnaire_disclaimer_actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding-top: 30px;

        .imm__regular__btn {
            padding-left: 30px;
            padding-right: 30px;

            &:disabled {
                background-color: #c0c5ce;
                color: var(--white_color);
                cursor: initial;

                &:hover {
                    background-color: #c0c5ce;
                    color: var(--white_color);
                }
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__questionnaire_disclaimer__page_view {
        .imm__questionnaire_disclaimer_content_section {
            padding: 15px;

            ol {
                padding-left: 1.5rem;

                li {
                    font-size: 14px;

                    a {
                        font-size: 14px;
                    }
                }
            }

            p {
                font-size: 14px;

                a {
                    font-size: 14px;
                }
            }

            .imm__questionnaire_disclaimer_checkbox_section {
                .imm__qdcs__checkbox {
                    gap: 10px;

                    label {
                        font-size: 14px;

                        a {
                            font-size: 14px;
                        }
                    }
                }
            }

            .imm__questionnaire_disclaimer_actions {
                .imm__regular__btn {
                    padding-left: 20px;
                    padding-right: 20px;
                    gap: 10px;
                }
            }
        }
    }
}