.imm__live_help__modal_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 2000;
    padding: 0 16px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); 
        backdrop-filter: blur(2px);
        z-index: 1; 
    }

    .imm__live_help__modal {
        width: 100%;
        position: relative;
        background-color: var(--white_color);
        border-radius: 16px 16px 0 0;
        box-shadow: var(--primary_shadow_even);
        border: 1px solid var(--primary_color);
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 30px 24px;

        @media screen and (min-width: 768px) {
            width: 50%;
            padding: 30px 40px;
        }

        .imm__live_help__modal__header  {
            position: relative;

            .imm__live_help__modal__header__close_btn {
                position: absolute;
                top: -16px;
                right: -8px;
                translate: transform(-50%, -50%);
                svg {
                    min-width: 28px;
                    min-height: 28px;
                }
            }

            @media screen and (min-width: 768px) {
               .imm__live_help__modal__header__close_btn {
                svg {
                    min-width: 32px;
                    min-height: 32px;
                }
            }
                
            }

            .imm__live_help__modal__header__title {
                font-weight: 500;
            }
        }

        .imm__live_help__modal__body {
            display: flex;
            flex-direction: column;
            gap:8px;

            .imm__live_help__modal__body__card {
                display: flex;
                gap: 8px;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 14px 12px;
                border-radius: 14px;
                border: 1px solid var(--primary_color); 

                .imm__live_help__modal__body__card__icon {
                    img {
                        width: 28px;
                        height: 28px;
                    }
                }

                .imm__live_help__modal__body__card__content {
                    display: flex;
                    flex-direction: column;

                    .imm__live_help__modal__body__card__content__body {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        margin-top: -4px;
                        .imm__live_help__modal__body__card__content__online_icon { 
                            min-width: 8px;
                            min-height: 8px;
                            border-radius: 50%;
                            background-color: var(--secondary_color);
                        }
                        .imm__live_help__modal__body__card__content__online_text {
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                }

            }
        }
    }
}