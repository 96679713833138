.imm__cancel_subscription__modal_overlay {
    --imm__cancel_subscription_modal__max_width: 530px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__cancel_subscription__modal_overlay {
        --imm__cancel_subscription_modal__max_width: 100%;
    }
}

.ReactModal__Content--after-open.imm__cancel_subscription__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__cancel_subscription__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__cancel_subscription_modal__max_width);
}

.imm__cancel_subscription__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;

    .imm__cancel_subscription_inner_wrap {
        position: relative;
        background-color: var(--tertiary_color);
        border-radius: 7.5px;
        border: 1px solid #e3e3e2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 5px 15px;

        .close_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary_color);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;

            &:hover {
                color: var(--primary_hover_color);
            }

            svg {
                width: 35px;
                height: 35px;
                min-width: 35px;
                max-width: 35px;
                stroke-width: 0.5;
            }
        }

        .imm__cancel__model_view {
            padding: 10px;

            h5 {
                font-weight: normal;
            }

            .imm__cancel__model_view_radio_groups {
                padding-bottom: 10px;

                label {
                    font-size: 12px;
                    font-weight: normal;
                    color: var(--black_color);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    input {
                        width: auto;
                    }
                }
            }

            textarea {
                padding: 5px;
            }

            p.small {
                font-size: 10px;
                text-align: right;
            }
        }

    }
}

.imm__cancel__success_box {
    padding: 15px;
    text-align: center;
}

@media #{map-get($media_queries, mobile)} {
    .ReactModal__Content--after-open.imm__cancel_subscription__modal_dialog {
        top: 0;
    }

    .imm__cancel_subscription__modal_overlay {
        background-color: var(--tertiary_color);
    }

    .imm__cancel_subscription__modal_dialog {
        padding: 0;
    }

    .imm__cancel_subscription__modal_content {
        padding: 15px;
        border-radius: 0;
        background-color: var(--tertiary_color);
        box-shadow: none;

        .imm__cancel_subscription_inner_wrap {
            border-radius: none;
            border: none;
            padding: 5px 0 20px 0;
        }
    }
}