.imm__notificaton_emails__opt_out {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 1rem;
    text-align: center;

    .imm__notificaton_emails__opt_out__main_text {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .imm__notificaton_emails__opt_out__sub_text {
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .imm__notificaton_emails__opt_out__error {
        color: var(--error_color);
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}