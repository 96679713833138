@-moz-keyframes spin-anim {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-anim {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin-anim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}