.imm__safe_and_secure_cont {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    

    svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        fill: var(--secondary_color);
    }

    p {
        font-size: 16px;
        line-height: 20px;
        color: var(--grey_color);
    }

    @media screen and (max-width: 768px) {
        p {
            font-size: 14px;
        }
        
        svg {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
        }
    }
}