.imm__auth_success__page_view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary_color);
    color: var(--white_color);

    .main_text {
        text-align: center;
    }

    .sub_text {
        text-align: center;
    }
}