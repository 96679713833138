.imm__manage_invitations__page_view {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__manage_invitations_list_section {

        .imm__manage_invitations_loading,
        .imm__manage_invitations_no_invitations_message {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            margin: 50px 0;

            h4 {
                color: #808080
            }
        }

        .imm__manage_invitations_card {
            width: 100%;
            background-color: var(--white_color);
            box-shadow: var(--primary_shadow_even);
            border-radius: 14px;
            padding: 16px;
            margin-bottom: 20px;

            h5 {
                font-weight: 400;
                margin: 4px 0;
            }

            .imm__manage_invitations_row {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .imm__manage_invitations_action {
            display: flex;
            flex-direction: row-reverse;

            button {
                gap: 5px;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__manage_invitations__page_view {
        .imm__manage_invitations_top {
            button {
                font-size: 12px;
            }
        }
    }
}