.imm__questionnaire__calender_component {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--white_color);
    padding: 10px;
    user-select: none;
    cursor: pointer;
    height: 355px;
    max-height: 355px;

    .imm__questionnaire__calender__icon_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 8px;
        color: var(--primary_color);
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;

        svg {
            width: 22px;
            height: 22px;
            min-width: 22px;
            min-height: 22px;
        }

        &:hover {
            background-color: var(--primary_color_lightest);
        }
    }

    .imm__questionnaire__calender__text_btn {
        width: 100%;
        height: 30px;
        min-height: 30px;
        padding: 0 15px;
        border-radius: 10px;
        font-weight: 500;
        font-size: 14px;
        color: var(--primary_color);

        &:hover {
            background-color: var(--primary_color_lightest);
        }
    }

    .imm__questionnaire__calender__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        .imm__questionnaire__calender__top__btn_grp {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .imm__questionnaire__calender__body {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .imm__questionnaire__calender__days_view {
            .imm__questionnaire__calender__days {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 8px;

                .imm__questionnaire__calender__day {
                    width: calc(100% / 7);
                    color: var(--dark_grey_color);
                    text-align: center;
                    font-weight: 600;
                    font-size: 13px;
                }
            }

            .imm__questionnaire__calender__dates {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;

                .imm__questionnaire__calender__date__btn__selected {
                    background-color: var(--primary_color) !important;
                    color: var(--white_color) !important;
                }

                .imm__questionnaire__calender__date__btn {
                    width: calc(100% / 7);
                    height: 35px;
                    min-height: 35px;
                    border-radius: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--dark_grey_color);

                    &:hover {
                        background-color: var(--primary_color_light);
                        color: var(--primary_color);
                    }
                }
            }
        }

        .imm__questionnaire__calender__months_view {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            height: 100%;

            .imm__questionnaire__calender__month__btn__selected {
                background-color: var(--primary_color_light) !important;
                color: var(--primary_color) !important;
            }

            .imm__questionnaire__calender__month__btn {
                width: calc(100% / 4);
                height: calc(100% / 3);
                font-weight: 600;
                border-radius: 10px;
                font-size: 15px;
                color: var(--dark_grey_color);

                &:hover {
                    background-color: var(--primary_color_lightest);
                    color: var(--primary_color);
                }
            }
        }

        .imm__questionnaire__calender__years_view {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            .imm__questionnaire__calender__year__btn__selected {
                background-color: var(--primary_color_light) !important;
                color: var(--primary_color) !important;
            }

            .imm__questionnaire__calender__year__btn {
                width: calc(100% / 4);
                height: 37px;
                min-height: 37px;
                font-weight: 500;
                border-radius: 10px;
                font-size: 15px;
                color: var(--dark_grey_color);

                &:hover {
                    background-color: var(--primary_color_lightest);
                    color: var(--primary_color);
                }
            }
        }
    }

    .imm__questionnaire__calender__bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }
}