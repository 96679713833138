@mixin m0_p0_mb0 {
    margin: 0;
    padding: 0;
    margin-block: 0;
}

@for $i from 1 through 6 {
    h#{$i} {
        @include m0_p0_mb0;
    }
}

p {
    @include m0_p0_mb0;
}

a {
    @include m0_p0_mb0;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary_color);

    &:hover {
        color: var(--primary_hover_color);
    }
}

button,
input,
textarea {
    @include m0_p0_mb0;
    font-family: var(--primary-font-family);
    -webkit-font-smoothing: antialiased;
    outline: none;
    border: none;
    color: var(--dark_color);
}

input:focus,
textarea:focus {
    outline: none;
    border: none;
}

input,
textarea {
    width: 100%;

    &::placeholder {
        color: var(--medium_color);
    }
}

button {
    @include m0_p0_mb0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
}

video {
    outline: none;

    &:focus {
        outline: none;
    }
}

ul {
    @include m0_p0_mb0;
}

li {
    list-style: none;
}

select {
    outline: none;

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
}