.imm__payment_confirmed__page_view {
    .imm__payment_confirmed_container {
        // border: 3px solid var(--primary_color);
        border-radius: 20px;
        padding: 30px 20px;
        margin: 50px auto;
        width: calc(100% - 30px);
        max-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        svg {
            width: 70px;
            height: 70px;
            min-width: 70px;
            max-width: 70px;
        }
    }
}