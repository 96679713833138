* {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

span {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}

strong {
    font-size: inherit;
    font-weight: 600;
}

.mice {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.438px;
}

h1 {
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
}

h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
}

h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.75px;
}

h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.625px;
}

h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}

h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.438px;
}

.tab1 {
    margin-left: 40px;
}

.tab2 {
    margin-left: 60px;
}

.tab3 {
    margin-left: 80px;
}

.tab4 {
    margin-left: 100px;
}

@media only screen and (max-width: 991.98px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        letter-spacing: normal;
    }
}

@media #{map-get($media_queries, mobile)} {
    h1 {
        font-size: 32px;
        line-height: 40px;
    }

    h2 {
        font-size: 26px;
        line-height: normal;
    }

    h3 {
        font-size: 20px;
        line-height: normal;
    }

    h4 {
        font-size: 16px;
        line-height: normal;
    }

    h5 {
        font-size: 14px;
        line-height: normal;
    }

    h6 {
        font-size: 12px;
        line-height: 21px;
    }
}

@for $i from 100 through 900 {
    .wt-#{$i} {
        font-weight: #{$i} !important;
    }
}