.imm__footer__fixed {
    position: fixed;
    bottom: 0;
}

.imm__footer {
    background-color: var(--primary_color);
    padding: 60px 20px 25px 20px;
    color: var(--white_color);

    .footer__content {
        max-width: 1320px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 auto;

        .footer_content__left,
        .footer_content__right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        .footer_content__left {
            align-items: flex-start;

            .imm__footer__logo {
                display: flex;
                justify-content: center;
                align-items: center;
                width: max-content;
                margin-bottom: 57px;
                height: 35px;
            }
        }

        .footer_content__right {
            align-items: flex-end;

            .footer_btn_menu {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .social_btns_grp {
                a {
                    padding-left: 20px;
                }
            }

            .policy_links {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                a {
                    padding-left: 30px;
                    color: var(--white_color);

                    &:hover {
                        color: var(--secondary_color);
                    }
                }
            }
        }

        .social_btns_grp {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 15px 0 50px 0;

            a {
                color: var(--white_color);

                &:hover {
                    color: var(--secondary_color);
                }

                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width: 950px) {
    .imm__footer {
        padding: 60px 20px 10px 20px;

        .footer__content {
            // flex-direction: column;
            max-width: 100% !important;
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__footer {
        padding: 20px 15px 15px 15px;

        .footer__content {
            flex-direction: column;
            max-width: 100% !important;
        }

        .footer_content__left,
        .footer_content__right {
            width: 100%;
        }

        .footer_content__right {
            align-items: flex-start !important;

            .fc_right_p {
                font-size: 14px;
            }
        }

        .imm__footer__logo {
            max-height: 30px !important;
            margin-bottom: 20px !important;
        }

        .social_btns_grp {
            width: 100%;
            margin: 10px 0 25px 0 !important;

            a {
                padding-left: 0 !important;
                padding-right: 20px;

                svg {
                    height: 20px !important;
                    width: 20px !important;
                }
            }
        }

        .policy_links {
            width: 100%;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            gap: 5px;
            margin-bottom: 10px;

            a {
                font-size: 14px;
                padding-left: 0 !important;
            }
        }

        .mice {
            font-size: 12px;
        }
    }
}