.imm__notifications__page_view {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__notifications_top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            text-decoration: underline;
            font-size: 14px;
            color: var(--primary_color);

            &:hover {
                color: var(--primary_hover_color);
            }
        }
    }

    .imm__notifications_list_section {

        .imm__notifications_loading,
        .imm__notifications_no_noti_message {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            margin: 50px 0;
        }

        .imm__notifications_no_noti_message {
            h4 {
                color: #808080
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__notifications__page_view {
        .imm__notifications_top {
            button {
                font-size: 12px;
            }
        }
    }
}