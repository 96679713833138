@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

@keyframes imm__loader__rotate {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.imm__btn_loader {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border: 3px solid var(--primary_color);
    border-bottom-color: transparent !important;
    border-radius: 100px;
    animation: imm__loader__rotate 1s linear infinite;
}

.imm__list_loader {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    border: 3px solid var(--primary_color);
    border-bottom-color: transparent !important;
    border-radius: 100px;
    animation: imm__loader__rotate 1s linear infinite;
}