.imm__questionnaire__view {
    width: 100%;
    max-width: 1036px;
    margin: 0 auto;
    background-color: var(--white_color);

    .imm__questionnaire__inner_wrap {
        width: 100%;
        max-width: 824px;
        margin: 0 auto;
        padding: 50px 0;

        .imm__questionnaire__user_section {
            background-color: var(--white_color);
            border: 1px solid var(--medium_color);
            border-radius: 30px;
            padding: 5px 20px;
            user-select: none;
            text-align: center;
            width: max-content;
            margin: 0 auto 30px auto;
            font-weight: 600;
            font-size: 14px;
        }

        .imm__questionnaire__main_section {
            background-color: #FAFBFF;
            padding: 25px;
            border-radius: 14px;
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__questionnaire__view {
        background-color: transparent;

        .imm__questionnaire__inner_wrap {
            .imm__questionnaire__main_section {
                background-color: transparent;
                padding: 0;
                border-radius: 0;
            }
        }
    }
}

.imm__questionnaire__sections_pills_section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin: 0 auto 30px auto;
    overflow-x: auto;
    padding-bottom: 10px;
    width: fit-content;
    max-width: 100%;

    .imm__questionnaire__sections_pill {
        min-width: fit-content;
        max-width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        padding: 5px 12px;
        background-color: var(--white_color);
        border: 0.5px solid var(--medium_color);
        height: 36px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
            background-color: var(--medium_color);
        }

        svg {
            height: 16px;
            width: 16px;
            min-height: 16px;
            min-width: 16px;
        }

        span {
            font-size: 12px;
            font-weight: 500;
        }
    }

    .imm__questionnaire__sections_pill__selected {
        background-color: var(--medium_color);
    }

    .imm__questionnaire__sections_pill__disabled {
        background-color: var(--disabled_color);
        border-color: var(--disabled_color);
        cursor: not-allowed;

        &:hover {
            background-color: var(--disabled_color);
            border-color: var(--disabled_color);
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__questionnaire__sections_pills_section {
        margin: 0 auto 10px auto;

        .imm__questionnaire__sections_pill {
            max-height: 32px;
        }
    }
}