.imm__manage_connection__modal_overlay {
    --imm__manage_connection_modal__max_width: 530px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__manage_connection__modal_overlay {
        --imm__manage_connection_modal__max_width: 100%;
    }
}

.ReactModal__Content--after-open.imm__manage_connection__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__manage_connection__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__manage_connection_modal__max_width);
}

.imm__manage_connection__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;

    .imm__manage_connection_inner_wrap {
        position: relative;
        background-color: var(--tertiary_color);
        border-radius: 7.5px;
        border: 1px solid #e3e3e2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 25px 15px;

        .close_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary_color);
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;

            &:hover {
                color: var(--primary_hover_color);
            }

            svg {
                width: 35px;
                height: 35px;
                min-width: 35px;
                max-width: 35px;
                stroke-width: 0.5;
            }
        }

        .imm__manage_connection__model_view {
            width: 100%;
        }

    }

    .imm__manage_connection_inner {
        .imm__manage_connection_inner_box {
            width: 100%;
            background-color: var(--white_color);
            box-shadow: var(--primary_shadow_even);
            border-radius: 14px;
            padding: 20px;
            margin-bottom: 20px;

            .imm__employer_invitations__list {
                margin-top: 20px;
                width: 100%;

                .imm__employer_invitation__item {
                    border-bottom: 1px solid #e3e3e2;
                    width: 100%;
                    padding: 15px 0;

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }

                    .imm__employer_invitation__action {
                        padding-top: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 15px;

                        button {
                            min-width: 170px;
                        }
                    }
                }
            }

            .imm__manage_spouse__actions_box {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .imm__regular__btn {
                    padding: 0 40px !important;
                    margin: 0 5px;
                }
            }

            .imm__error__btn {
                color: var(--error_color);
                border-color: var(--error_color);
            }

            .imm__manage_spouse__model_view_input_group {
                padding-bottom: 35px;

                label {
                    font-size: 14px;
                    font-weight: 500;
                    display: block;
                    color: var(--primary_color);
                }

                input, textarea {
                    width: 100%;
                    padding: 5px 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--dark_color);
                    border-bottom: solid 1px var(--primary_color);
                }

                select {
                    width: 100%;
                    padding: 5px 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--dark_color);
                    border: none;
                    border-bottom: solid 1px var(--primary_color);
                }
            }

        }
    }


}

@media #{map-get($media_queries, mobile)} {
    .ReactModal__Content--after-open.imm__manage_connection__modal_dialog {
        top: 0;
    }

    .imm__manage_connection__modal_overlay {
        background-color: var(--tertiary_color);
    }

    .imm__manage_connection__modal_dialog {
        padding: 0;
    }

    .imm__manage_connection__modal_content {
        padding: 15px;
        border-radius: 0;
        background-color: var(--tertiary_color);
        box-shadow: none;

        .imm__manage_connection_inner_wrap {
            border-radius: none;
            border: none;
            padding: 5px 0 20px 0;

            .close_btn {
                top: 0;
                right: 0;
            }
        }
    }
}