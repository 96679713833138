.imm__questionnaire_dashboard__page_view {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__questionnaire_dashboard_status_section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__questionnaire_dashboard__page_view {
        .imm__questionnaire_dashboard_status_section {
            gap: 20px;
        }
    }
}