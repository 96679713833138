.imm__account__page_view {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__account__user_info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        border-bottom: solid 1px #D8D8D8;
        padding-bottom: 10px;


        .imm__user_avtar {
            font-size: 16px;
            font-weight: 500;
            border: solid 2px #AEC5ED;
            background-color: var(--white_color);
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .imm__account__details__form {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        margin-top: 8px;
        width: 100%;
        border-bottom: solid 1px #D8D8D8;
        .imm__account__form_content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px 20px;
        }
        @media (max-width: 768px) {
            .imm__account__form_content {
                grid-template-columns: 1fr;
            }
        }

        .imm__account__form_label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .imm__account__form_input {
            border: solid 1px var(--primary_color);
            border-radius: 8px;
            padding: 10px;
            width: 100%;
            margin-bottom: 10px;
            color: var(--dark_color);
            background-color: var(--white_color);
            font-size: 14px;

            &:disabled {
                background-color: var(--light_grey_color);
                color: var(--grey_color);
                border: solid 1px var(--light_grey_color);
            }
        }

        .imm__account__form_actions {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 20px;

            .imm__account__form_actions_save_btn {
                padding: 10px 20px;
                border-radius: 40px;
                background-color: var(--primary_color);
                color: var(--white_color);
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                border: none;
                box-shadow: var(--primary_shadow_even);
                transition: all 0.3s ease;

                &:hover {
                    box-shadow: var(--primary_shadow_odd);
                }

                // disbaled effect
                &:disabled {
                    background-color: var(--light_grey_color);
                    color: var(--grey_color);
                    cursor: not-allowed;
                    box-shadow: none;
                }
            }
        }

        .imm__account__form_error {
            color: var(--error_color);
            font-size: 14px;
            margin-top: 5px;
        }
    }

    .imm__account__two_factor {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0 0 10px 0;
        border-bottom: solid 1px #D8D8D8;

        .imm__account__two_factor_title {
            font-size: 20px;
            font-weight: 600;
        }

        .imm__account__two_factor_description {
            font-size: 14px;
            font-weight: 400;
        }

        .imm__account__two_factor_action {
            margin-top: 12px;
            display: flex;
            gap:12px;
            justify-content: flex-end;
            align-items: flex-end;

            .imm__account__two_factor_action_btn {
                min-width: 140px;
                padding: 8px 12px;
                border-radius: 40px;
                background-color: var(--primary_color);
                color: var(--white_color);
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                border: none;
                box-shadow: var(--primary_shadow_even);
                transition: all 0.3s ease;
            }

            .imm__account__two_factor_action_btn_secondary_btn {
                background-color: var(--white_color);
                color: var(--primary_color);
                border : solid 1px var(--primary_color);
                font-size: 14px;

                &:disabled {
                    background-color: var(--light_grey_color);
                    color: var(--grey_color);
                    cursor: not-allowed;
                    box-shadow: none;
                    border: solid 1px var(--light_grey_color);
                }
            }

            .imm__account__two_factor_error {
                color: var(--error_color);
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }

    .imm__account__subscription {
        .imm__account__plan_box {
            border: solid 1px var(--secondary_color);
            cursor: pointer;
            background-color: var(--white_color);
            box-shadow: var(--primary_shadow_even);
            border-radius: 16px;
            padding: 14px 24px;
            margin-top: 20px;
            width: 100%;

            .imm__account__plan_row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    &:first-child {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}