.imm_quest__countries_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}

@media screen and (max-width: 600px) {
    .imm_quest__countries_list {
        grid-template-columns: repeat(2, 1fr);
    }
}

.imm__questionnaire_not_available {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #FBF4F4;
    padding: 24px;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: var(--primary_shadow_even);

    svg {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
    }
}