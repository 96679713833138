.imm__spouse_added_message__modal_overlay {
    --imm__spouse_added_message_modal__max_width: 530px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .imm__spouse_added_message__modal_overlay {
        --imm__spouse_added_message_modal__max_width: 100%;
    }
}

.ReactModal__Content--after-open.imm__spouse_added_message__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__spouse_added_message__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__spouse_added_message_modal__max_width);
}

.imm__spouse_added_message__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;

    .imm__spouse_added_message_inner_wrap {
        position: relative;
        background-color: var(--tertiary_color);
        border-radius: 7.5px;
        border: 1px solid #e3e3e2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 5px 15px;

        .close_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary_color);
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 2;

            &:hover {
                color: var(--primary_hover_color);
            }

            svg {
                width: 35px;
                height: 35px;
                min-width: 35px;
                max-width: 35px;
                stroke-width: 0.5;
            }
        }

        .imm__spouse_added__model_view {
            width: 100%
        }

    }
}

.imm__cancel__success_box {
    padding: 15px;
    text-align: center;
}

@media #{map-get($media_queries, mobile)} {
    .ReactModal__Content--after-open.imm__spouse_added_message__modal_dialog {
        top: 0;
    }

    .imm__spouse_added_message__modal_overlay {
        background-color: var(--tertiary_color);
    }

    .imm__spouse_added_message__modal_dialog {
        padding: 0;
    }

    .imm__spouse_added_message__modal_content {
        padding: 15px;
        border-radius: 0;
        background-color: var(--tertiary_color);
        box-shadow: none;

        .imm__spouse_added_message_inner_wrap {
            border-radius: none;
            border: none;
            padding: 5px 0 20px 0;

            .close_btn {
                top: 0;
                right: 0;
            }
        }
    }
}