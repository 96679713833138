@import "imports";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-font-family: 'Poppins', sans-serif;
    --primary_shadow_offset_bottom: 0 5pt 8pt 0 var(--primary_shadow_color);
    --primary_shadow_even: 0 0 12pt 0 var(--primary_shadow_color);
}

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    background-color: var(--tertiary_color);
    -webkit-font-smoothing: antialiased !important;
    font-family: var(--primary-font-family) !important;
    color: var(--dark_color);
}

#root {
    width: 100%;
}

.imm__immproved_app {
    width: 100%;
}