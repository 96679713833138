.imm__status_manager_dashboard__page_view {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;

    .imm__status_manager_dashboard_status_section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__status_manager_dashboard__page_view {
        .imm__status_manager_dashboard_status_section {
            gap: 20px;

            .imm__status_manager_dashboard_loading,
            .imm__status_manager_dashboard_error {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 15px;
                margin: 50px 0;
            }
        }
    }
}