.imm__incomplete_questionnaire__modal_overlay {
    --imm__incomplete_questionnaire_modal__max_width: 500px;
}

.ReactModal__Content--after-open.imm__incomplete_questionnaire__modal_dialog {
    top: 40px;
    transition-duration: .3s;
}

.imm__incomplete_questionnaire__modal_dialog {
    top: 0;
    transform: translate(-50%, 0) !important;
    width: var(--imm__incomplete_questionnaire_modal__max_width);
}

.imm__incomplete_questionnaire__modal_content {
    background-color: var(--white_color);
    box-shadow: 0 0 30px 5px #00000044;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    position: relative;

    .imm__modal_close_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--dark_color);
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;

        &:hover {
            color: var(--primary_color);
        }

        svg {
            width: 24px;
            height: 24px;
            min-width: 24px;
            max-width: 24px;
            stroke-width: 0.5;
        }
    }
}

.imm__incomplete_questionnaire__modal_body {
    z-index: 1;
    padding: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.imm__incomplete_questionnaire__modal_footer {
    border-top: 1px solid #00000018;
    padding: 15px 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 500px) {
    .imm__incomplete_questionnaire__modal_overlay {
        --imm__incomplete_questionnaire_modal__max_width: 100%;
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__incomplete_questionnaire__modal_content {
        padding: 10px;

        .imm__modal_close_btn {
            svg {
                width: 20px;
                height: 20px;
                min-width: 20px;
                max-width: 20px;
            }
        }
    }

    .imm__incomplete_questionnaire__modal_body {
        padding: 10px 0;
        gap: 10px;
    }

    .imm__incomplete_questionnaire__modal_footer {
        padding: 10px 0 0 0;
    }
}

.imm__questionnaire_completion_warning {
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    svg {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;
    }

    .incomplete_pages {
        width: 100%;
        padding: 0 10px;

        h4 {
            padding: 0;
            padding-bottom: 10px;
            color: var(--primary_color);
        }

        .incomplete_pages__page {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;

            p {
                font-weight: 600;
            }

            button {
                color: var(--primary_color);
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media #{map-get($media_queries, mobile)} {
    .imm__questionnaire_completion_warning {
        margin: 5px;
        padding: 15px 10px;

        p {
            font-size: 14px;
        }
    }
}