.imm__spouse_quest_incomplete_note {
    margin-top: 25px;
    padding: 16px 24px;
    background-color: #FBF4F4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    border-radius: 4px;
    box-shadow: var(--primary_shadow_even);

    svg {
        min-height: 24px;
        min-width: 24px;
        color: var(--error_color);
    }

    .right_side_gt {

        span,
        a {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.438px;
        }

        a {
            text-decoration: underline;
            display: inline;
        }
    }
}

.imm__score_projection_note {
    margin-top: 25px;
    padding: 16px 24px;
    background-color: #FBF4F4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    border-radius: 4px;
    box-shadow: var(--primary_shadow_even);

    svg {
        min-height: 24px;
        min-width: 24px;
        color: var(--error_color);
    }

    span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.438px;
    }
}

@media #{map-get($media_queries, desktop)} {

    .imm__score_projection_note {
        span {
            font-size: 14px;
        }
    }

    .imm__spouse_quest_incomplete_note {
        .right_side_gt {

            span,
            a {
                font-size: 14px;
            }
        }
    }
}

.imm__want_more_options_note {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 24px;
    background-color: var(--white_color);
    border-radius: 14px;
    box-shadow: var(--primary_shadow_even);

    .imm__want_more_options__left {

        h4,
        p {
            text-align: left;
        }

        p {
            padding: 10px 0 16px 0;
        }

        button {
            background-color: var(--primary_color);
            color: var(--white_color);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.438px;
            border-radius: 50px;
            width: max-content;
            padding: 0 15px;
            box-shadow: var(--primary_shadow_offset_bottom);

            &:hover {
                background-color: var(--primary_hover_color);
            }
        }

        .imm__want_more_premium_text {
            color: var(--success_color);
            text-transform: uppercase;
        }
    }

    .imm__want_more_options__right {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 108px;
        }
    }
}

.imm__speak_to_us_note {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
    padding: 16px 24px;
    background-color: var(--white_color);
    border-radius: 14px;
    box-shadow: var(--primary_shadow_even);

    a {
        background-color: var(--primary_color);
        color: var(--white_color);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.438px;
        border-radius: 50px;
        width: max-content;
        padding: 0 15px;
        box-shadow: var(--primary_shadow_offset_bottom);

        &:hover {
            background-color: var(--primary_hover_color);
            color: var(--white_color);
        }
    }
}

.imm__score_hypo_card__blurry {
    // backdrop-filter: blur(20px);
    // backdrop-filter: saturate(180%) blur(20px);
}

.imm__score_hypo_card {
    margin-top: 20px;
    padding: 16px 24px;
    background-color: var(--white_color);
    border-radius: 14px;
    box-shadow: var(--primary_shadow_even);
    position: relative;
    overflow: hidden;

    --difficulty_color: var(--primary_color);

    .imm__shc__blurry_film {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(7px);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .imm__shc__blurry_film__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            svg {
                width: 32px;
                height: 32px;
                min-width: 32px;
                min-height: 32px;
            }

            button {
                background-color: var(--primary_color);
                color: var(--white_color);
                border-radius: 50px;
                padding: 5px 20px;
                font-size: 12px;
                font-weight: 600;

                &:hover {
                    background-color: var(--primary_hover_color);
                }
            }
        }
    }

    .imm__shc__d__easier {
        --difficulty_color: var(--success_color);

        .imm__shc__dots {

            span:nth-child(1),
            span:nth-child(2),
            span:nth-child(3) {
                background-color: var(--difficulty_color);
            }
        }
    }

    .imm__shc__d__medium {
        --difficulty_color: var(--warning_color);

        .imm__shc__dots {

            span:nth-child(1),
            span:nth-child(2) {
                background-color: var(--difficulty_color);
            }
        }
    }

    .imm__shc__d__hard {
        --difficulty_color: var(--error_color);

        .imm__shc__dots {

            span:nth-child(1) {
                background-color: var(--difficulty_color);
            }
        }
    }

    .imm__shc__top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
    }

    .imm__shc__score {
        color: var(--success_color);
        font-weight: 600;
    }

    .imm__shc__difficulty {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        border: 1px solid var(--difficulty_color);
        border-radius: 30px;
        padding: 2px 14px;
        width: fit-content;

        .imm__shc__dots {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;

            span {
                border: 1px solid var(--difficulty_color);
                height: 5px;
                width: 5px;
                border-radius: 10px;
            }
        }

        .imm__shc__difficulty_title {
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 12px;
            letter-spacing: 0.5px;
            color: var(--difficulty_color);
        }
    }

    .imm__shc__favorite_container {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        align-items: flex-start;

        .imm__shc__heart {
            cursor: pointer;

            .heart_fill {
                color: var(--heart_red_color);

                &:hover {
                    color: var(--heart_red_hover_color);
                }
            }

            svg {
                width: 32px;
                height: 29px;
                min-width: 32px;
                min-height: 29px;
            }
        }
    }

    .imm__shc__hypo_content {
        margin-top: 10px;

        .imm__shc__hypo_content_bullet {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            .imm__shc__hypo_content__dot {
                margin-top: 8px;
                background-color: var(--dark_color);
                width: 5px;
                height: 5px;
                border-radius: 10px;
            }
        }
    }
}

.imm__selected_category_card {
    margin-bottom: 20px;
    padding: 16px 24px;
    background-color: var(--white_color);
    border-radius: 14px;
    box-shadow: var(--primary_shadow_even);

    a {
        margin-top: 5px;
        justify-content: left;
        text-decoration-line: underline;
        color: var(--primary_color);
        width: fit-content;

        &:hover {
            text-decoration-line: none;
            color: var(--primary_hover_color);
        }
    }
}

.imm__results__outer_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    min-height: 14px;
    min-width: 14px;
    border-radius: 100%;
    background-color: var(--white_color);
    box-shadow: var(--primary_shadow_even);

    .imm__results__inner_circle {
        width: 8px;
        height: 8px;
        min-height: 8px;
        min-width: 8px;
        border-radius: 100%;
        background-color: var(--secondary_color);

        &.color_difficult {
            background-color: var(--error_color);
        }

        &.color_medium {
            background-color: var(--warning_color);
        }

        &.color_easy {
            background-color: var(--secondary_color);
        }
    }
}