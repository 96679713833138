.ReactModal__Body--open {
    overflow: hidden !important;
}

.imm__modal_overlay,
.imm__modal_dialog {
    outline: none;

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
}

.imm__modal_overlay {
    z-index: map-get($z_indexes, modal-backdrop);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #070b6730;
    transition: 0.3s all;
    backdrop-filter: blur(2px);
}

.imm__modal_dialog {
    z-index: map-get($z_indexes, modal);
    position: fixed;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    padding: 15px;
}

@for $i from 1 through 3 {
    .imm__modal_overlay__topup#{$i} {
        z-index: calc(map-get($z_indexes, modal-backdrop) + #{$i});
    }

    .imm__modal_dialog__topup#{$i} {
        z-index: calc(map-get($z_indexes, modal) + #{$i});
    }
}